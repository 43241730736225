<template>
  <button class="nav-close button--unstyled" aria-label="Close navigation">
    <span class="icon icon-close" aria-hidden="true"></span>
  </button>
</template>

<script>
export default {
  name: 'CloseNavButton'
};
</script>

<style lang="scss">
.global-header {

  .nav-close {
    color: $color--red;
    font-size: 18px;
    position: absolute;
    right: 0;
    top: -25px;

    @include from-bp(1360px) {
      right: -40px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
