import { development as EXT_DEV_CONFIG } from "../../../config/environment-config-external.json";

// This mixin prepends the catadvisors.vanguard.com domain to each path when the
// Global Nav is loaded by eStudio in its dev region.

export default {
  methods: {
    addDomainToPath(path) {
      return !this.eStudio || path.includes("http", 0) || path.includes("tel:", 0)
        ? path
        : `${EXT_DEV_CONFIG.advisorDomain}${path}`;
    }
  },
  mounted() {
    this.eStudio = window.location.host === EXT_DEV_CONFIG.eStudioHost;
  }
};
