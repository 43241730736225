import 'es6-promise/auto';
import 'core-js/fn/object/entries';
import 'core-js/fn/object/assign';

import Vue from 'vue';
import GlobalNav from './wrapper';
import GlobalFooter from '@vg-faset/footer/src/Footer';
import { filters, plugins } from '@vg-faset/core';

Vue.use(filters);
Vue.use(plugins.smartFocusPlugin);

// Needed for eStudio since it adds scripts above the HTML
const documentReady = (fn) => {
	if(document.readyState === 'complete') {
		fn();
	} else {
		document.addEventListener('DOMContentLoaded', fn);
	}
};

documentReady(() => {
	let gnApp = new Vue({
		el: '#fas-global-nav',
		render: h => h(GlobalNav)
	});

	let gfApp = new Vue({
		el: '#fas-global-footer',
		render: h => h(GlobalFooter)
	});
});
