<template>
  <div class="nav-submenu box" @click="sendLaunchData">
    <div class="col sm-3 nav-column" v-for="(column, index) in siloColumns" :key="index" :class="{'sm-offset-1':index > 0,'--border-right' : (index === siloColumns.length-1 && siloColumns.length > 2 && silosContent.additionalResource.additionalresource.heading)}">
      <template v-for="(section, sectionIndex) in sectionsContent[column.sectionsName]">
        <h2 v-if="section.heading" class="p1 --bold" :id="'header-class'+index+sectionIndex" v-html="section.heading" :key="'header-class'+index+sectionIndex"></h2>
        <ul :aria-labelledby="'header-class'+index+sectionIndex" :key="'menuItems'+index+sectionIndex">
          <template v-for="(menu, menuIndex) in section.menuItems">
            <li v-if="menu.displayName && menu.displayName!=='Product screener' && menu.displayName !=='Factor investing tool'" :key="menuIndex">
              <a :href="isLoggedOn ? addDomainToPath(menu.secureLink) : addDomainToPath(menu.unSecureLink)" v-on="(index === 0 && sectionIndex === 0 && menuIndex === 0) ? {keydown: onTabBack}:{}" v-html="menu.displayName"></a>
            </li>
            <li v-else :key="menuIndex">
              <a v-if="menu.displayName && isInternalSite" :key="menuIndex" :href="isLoggedOn ? addDomainToPath(menu.secureLink) : addDomainToPath(menu.unSecureLink)" v-on="(index === 0 && sectionIndex === 0 && menuIndex === 0) ? {keydown: onTabBack}:{}" v-html="menu.displayName"></a>
            </li>
          </template>
          <template v-if="section.actionLink.actionlink.displayName">
            <li>
              <CTA
                :url="isLoggedOn ? addDomainToPath(section.actionLink.actionlink.secureLink) : addDomainToPath(section.actionLink.actionlink.unSecureLink)"
                :text="section.actionLink.actionlink.displayName"
                :chevron="'right'"
                :targetBlank="false"
                class="nav-column-cta" />
            </li>
          </template>
        </ul>
      </template>
    </div>
    <!-- If no column 3 here, just space -->
    <div v-if="siloColumns && siloColumns.length < 3 && silosContent.additionalResource.additionalresource && silosContent.additionalResource.additionalresource.heading" class="col sm-3 sm-offset-1 nav-column  --border-right"></div>
        <div v-if="silosContent.additionalResource.additionalresource && silosContent.additionalResource.additionalresource.heading" class="col sm-3 sm-offset-1 nav-column">
          <h2 class="p1 --bold" v-html="silosContent.additionalResource.additionalresource.heading"></h2>
          <a :href="addDomainToPath(silosContent.additionalResource.additionalresource.descriptionUrl)" class="nav-article" :aria-label="silosContent.additionalResource.additionalresource.descriptionText">
            <img :src="addDomainToPath(silosContent.additionalResource.additionalresource.image)" alt="">
            <span class="eyebrow">{{silosContent.additionalResource.additionalresource.date}}</span>
            <p class="p2" v-html="silosContent.additionalResource.additionalresource.descriptionText"></p>
      </a>
      <CTA
        v-if="silosContent.additionalResource.additionalresource.actionLink && silosContent.additionalResource.additionalresource.actionLink.actionlink"
        :url="isLoggedOn ? addDomainToPath(silosContent.additionalResource.additionalresource.actionLink.actionlink.secureLink)
        :addDomainToPath(silosContent.additionalResource.additionalresource.actionLink.actionlink.unSecureLink)"
        :text="silosContent.additionalResource.additionalresource.actionLink.actionlink.displayName"
        :chevron="'right'"
        :targetBlank="false"
        class="nav-column-cta" />
    </div>
    <!-- Close button -->
    <CloseNavButton @click.native="closePanel" @keydown.native="onTabForward" />
  </div>
</template>

<script>
  import { eStudioHelper, NavPanelHelpers } from '../../js/mixins';
  import CloseNavButton from './CloseNavButton';
  import CTA from '@vg-faset/ui/src/components/cta/CTA';
  import { adobeLaunch } from 'vg-fas-adobe-launch';

  export default {
    name: 'SiloLarge',
    mixins: [eStudioHelper, NavPanelHelpers],
    components: { CloseNavButton, CTA },
    props: {
      silosContent:{
          type: Object
      },
      sectionsContent:{
        type: Object
      },
      isLoggedOn: {
        type: Boolean
      }
    },
    watch: {
      silosContent:{
        immediate:true,
        handler(newVal){
          // eslint-disable-next-line vue/no-mutating-props
          this.silosContent = newVal;
          this.siloColumns = this.silosContent.columns;
          this.$parent.currentSubnavLabel = this.silosContent.displayName;
        }
      }
    },
    data() {
      return {
        siloColumns: this.silosContent.columns,
        isInternalSite: window.location.host.indexOf("fasi.vanguard.com") > -1
      };
    },
    methods: {
      sendLaunchData(e){
        if(e.target.tagName === "A" || e.target.parentNode.tagName === "A" ){
          adobeLaunch.trackNavigationClick({
            "navigationMenuItem": this.silosContent.displayName,
            "navigationLinkClicked": e.target.textContent
          });
        }
      }
    }
  };
</script>
