<template>
    <div class="global-toolbar container" @keyup="escKeyListener">
        <div class="box toolbar-items">
            <div class="toolbar-left col sm-4" :class="{ 'linkIndex' : panelOpen }">
                <a id="toolBarHeader" href="#" aria-haspopup="true" :aria-expanded="panelOpen ? 'true' : 'false'" class="cta toolbar-dropdown-trigger ie-focusable ie-focusable-only" @click.prevent="onDropdownClick" ref="trigger" :class="{ '--active' : panelOpen, '--no-pointer-events' : !fullHeader }">
                    {{headerUtilityBar.siteTitle}}<span v-show="fullHeader" class="icon icon-bold-down" aria-hidden="true"></span>
                </a>
            </div>

            <div class="toolbar-dropdown" ref="panel" :class="{ '--active' : panelOpen }">
                <span class="tooltip-arrow dropdown-arrow large-only" aria-hidden="true"></span>
                <div class="toolbar-dropdown-contents" role="navigation" :aria-label="ariaLables.moreVanguardSites" >

                    <!-- Close button (small view only) -->
                    <button class="close-x small-med-only" :aria-label="ariaLables.closeDropdown" @click="closeDropdown">
                        <span class="close-x__text p3 --bold">Close</span>
                        <span class="icon icon-close close-x__icon"></span>
                    </button>
                    <!-- Other Vanguard sites -->
                    <ul>
                        <li v-for="(item, index) in financialAdvDataList" :key="index" :id="'siteOption_' + index"
                            :class="index === 0 ? 'toolbar-site-options':'toolbar-dropdown-links large-only'">
                            <ul>
                                <li v-bind:class="[index === 0 ? 'toolbar-site-option':'toolbar-dropdown-link', preferredSite === innerItem.uiElemId ? '--current --bold' : '']"
                                    :key="innerIndex"
                                    v-on="(innerItem.uiElemId === 'SSP') ? ((innerIndex === item.length-1 && !model.isLarge) ? { click: showPrefsModal, keydown: onTabForward} : {click: showPrefsModal} ) : ((!model.isLarge && innerIndex === item.length-1) ? {keydown: onTabForward} : '')"
                                    v-for="(innerItem, innerIndex) in item" :id="'siteOptionItem_' + index + innerIndex">
                                    <CTA id="sitePrefLink" v-if="innerItem.uiElemId === 'SSP'"
                                         :url="'#'" :text="innerItem.siteDisplayName" :chevron="'right'"/>
                                    <a v-else :href="innerItem.urlSuffix" v-html="innerItem.siteDisplayName"
                                       v-on="(index === 0 && innerIndex === 0) ? {keydown: onTabBack} : ((index === financialAdvDataList.length-1 && innerIndex === item.length-1) ? {keydown: onTabForward} : '')" ></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="toolbar-right col sm-8" :class="{ '--hide-section' : !fullHeader }">
                <a v-if= "literature && isLoggedOn" :href="isLoggedOn ? headerUtilityBar.shoppingCart.shoppingcart.secureHref : headerUtilityBar.shoppingCart.shoppingcart.unSecureHref" class="cart-link" aria-label="cartAriaLabel">
                    <span class="toolbar-badge" v-html="literature"></span>
                    <span class="icon icon-cart toolbar-cart" aria-hidden="true"></span>
                </a>
                <a href="/tax-center" class="toolbar-link large-view ie-focusable ie-focusable-only">Tax Center</a>
                <a :href="headerUtilityBar.subscribe.subscribe.link" v-html="headerUtilityBar.subscribe.subscribe.label" class="toolbar-link large-view ie-focusable ie-focusable-only"></a>
                <a v-if="isLoggedOn && !this.checkInternal()" :href="headerUtilityBar.myProfile.myprofile.items[0].secureLink"
                   v-html="headerUtilityBar.myProfile.myprofile.secureLabel" class="toolbar-link large-view ie-focusable ie-focusable-only"></a>
                <a :href="headerUtilityBar.contactUs.contactus.link" v-html="headerUtilityBar.contactUs.contactus.label" class="toolbar-link large-view ie-focusable ie-focusable-only"></a>
                <a v-if="isLoggedOn" id="signInLink" href="#" v-html="headerUtilityBar.signOut.signout.secureLabel" class="toolbar-link large-view ie-focusable ie-focusable-only" @click="onSignOutClick"></a>
                <a v-else id="signInLink" href="#" v-html="headerUtilityBar.signIn.signin.unsecureLabel" class="toolbar-link large-view ie-focusable ie-focusable-only" @click="onSigninClick"></a>
                <a id="registerLink" :href="!isLoggedOn ? '/register' : '#'" v-html="isLoggedOn ? '' : headerUtilityBar.register.register.unsecureLabel"
                   v-if="!checkRegisterPage() && !checkInternal()"  class="toolbar-link large-view ie-focusable ie-focusable-only" ></a>
            </div>
        </div>
        <v-breakpoint v-model="model" />
    </div>
</template>

<script>
    import Vue from 'vue';
    import { VBreakpoint, Model, EventBus } from '@vg-faset/core';
    import SitePreference from '@vg-faset/ui/src/components/site-preference/SitePreference.vue';
    import { AdvisorCertification } from "@vg-faset/ui/src/components/advisor-certification";
    import CTA from '@vg-faset/ui/src/components/cta/CTA';
    import VueCookies from 'vue-cookies';
    import {adobeLaunch} from 'vg-fas-adobe-launch';
    import advancedCertificationService from "@/js/services/advancedCertification.service";
    import firmListService from "@/js/services/firm-list.service";
    import envConfigService from "../js/services/envConfig.service";
    import axios from "axios";

    Vue.use(VueCookies);
    let ENDPOINTS = require("../../config/endpoints.constant");
    export default {
        name: 'GlobalToolbar',
        props:{
            globalNAVData: {
                type: Object
            },
            isLoggedOn: {
                type: Boolean
            },
            fullHeader: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                model: new Model(),
                panelOpen: false,
                vgnContent: this.globalNAVData,
                headerUtilityBar: this.globalNAVData.headerutilitybar.globalHeaderUtilityBar.globalheaderutilitybar,
                ariaLables: this.globalNAVData.commoncontent.commonContent.arialablesforaccessibility,
                financialAdvDataList: [],
                preferredSite: 'N',
                literature: null,
                isInternalSite: false,
                cartAriaLabel: "",
                userIdentified: false,
                userIdentifiedInBasicCertLayer: false,
                firmList:[],
                suggestedFirmsList:[],
                inputs: {
                        first: [
                            {
                                message: "at least 2 characters long",
                                regex: /^.{2,}$/
                            },
                            {
                                message: "No special characters",
                                regex: /^[a-zA-Z0-9]*$/,
                                isNegative: true
                            }
                        ],
                        last: [
                            {
                                message: "at least 2 characters long",
                                regex: /^.{2,}$/
                            },
                            {
                                message: "No special characters",
                                regex: /^[a-zA-Z0-9]*$/,
                                isNegative: true
                            }
                        ],
                        email: [
                            {
                                message: "Must be in the following format: x@x.xx",
                                regex: /^[^@]+@[^@]*\w[^@]*\.[^@]+$/
                            }
                        ]
                      },
                isAdvancedCertificationLayerOpened : false,
                consumerID : ""
            };
        },
        components: {
            VBreakpoint,
            CTA
        },
        methods: {
            onDropdownClick() {
                if (this.fullHeader) {
                    this.getPreferredSite();
                    this.panelOpen = !this.panelOpen;
                    if (this.panelOpen) {
                        if (this.model.isLarge) {
                            document.body.classList.remove('--nav-open');
                        } else {
                            document.body.classList.add('--nav-open');
                        }
                    }
                }
            },
            isInternal() {
                const curLoc = window.location.host;
                this.isInternalSite = curLoc.indexOf("fasi.vanguard.com") > -1;
            },
            async onSigninClick(e) {
                if (e) {
                    e.preventDefault();
                }
                if (this.isInternalSite) {
                    const secureURL = "/flk-fas-globalnavigation-webapp/protected/login/redir?url=";
                    const target = `${window.location.pathname}${window.location.search}${window.location.hash}`;
                    adobeLaunch.trackSignInClick();
                    return window.location.assign(secureURL + target);
                }
                else {
                    await this.onAuth0LinkClick();
                }
            },

            clearVGSession() {
                axios.get(
                    envConfigService.buildWebappRegionURL() + ENDPOINTS.CLEAR_VG_SESSION_URL
                );
            },
            onSignOutClick(e, target = "") {
                if (this.checkInternal()) {
                    this.clearVGSession();
                    window.location.assign("/");
                } else {
                    this.onAuth0LinkClick(e, target);
                }
            },
            async onAuth0LinkClick(e, target = "") {
                this.clearVGSession();
                let url;
                let targetUrl;
                const env = await envConfigService.get();
                const signInUrl = env.auth0SignInURL;
                const signOutUrl = env.auth0SignOutURL;
                //const target = `${window.location.pathname}${window.location.search}${window.location.hash}`;
                if(target === "") {
                    if(window.location.hash) {
                        targetUrl = `${window.location.pathname}${window.location.search}` + "&urlhash=" + `${window.location.hash}`.substring(1);
                    } else{
                        targetUrl = `${window.location.pathname}${window.location.search}`;
                    }
                } else {
                    targetUrl = target;
                }

                /*if user clicked on sign in from register page, then redirect to home page*/
                if (targetUrl.endsWith('/register')) {
                  targetUrl = '';
                }

                adobeLaunch.trackSignInClick();
                if (e) {
                    e.preventDefault();
                }
                url = this.isLoggedOn ? signOutUrl : signInUrl + targetUrl;
                console.log("signInUrl ", signInUrl, " signOutUrl ", "target ", targetUrl, signOutUrl, " url ", url);
                return window.location.assign(url);
            },
            closeDropdown() {
                if (this.$refs.panel) {
                    this.$refs.panel.classList.remove('--active');
                    document.body.classList.remove('--nav-open');
                    this.panelOpen = false;
                }
            },

            // Like the Global Nav, this component implements some special listeners
            // so the panel will close once you tab outside of it.
            // Focus naturally moves where you'd expect it to in this case.
            onTabForward(e) {
                if (!e.shiftKey && e.key === 'Tab') {
                    this.closeDropdown();
                }
            },
            onTabBack(e) {
                if (e.shiftKey && e.key === 'Tab') {
                    this.closeDropdown();
                }
            },
            escKeyListener(e) {
                // Most browsers || IE
                if (e.key === 'Escape' || e.key === 'Esc') {
                    this.closeDropdown();
                    this.$refs.trigger.focus();
                }
            },
            clickListener(e) {
                const panel = this.$refs.panel;
                let targetElement = e.target;

                if (
                    targetElement === this.$refs.trigger ||
                    targetElement.parentElement === this.$refs.trigger
                ) {
                    return;
                }

                // Check if the click was inside the panel
                do {
                    if (targetElement === panel) {
                        return;
                    }
                    targetElement = targetElement.parentNode;
                } while (targetElement);

                // If it wasn't...
                this.closeDropdown();
            },
            // There's a link in here that opens a "set site preferences" modal
            showPrefsModal(e) {
                e.preventDefault();
                this.closeDropdown();
                EventBus.$emit('modal:set', SitePreference);
                EventBus.$emit('modal:set-title', 'Set site preference');
                EventBus.$emit('modal:show', e);
            },
            //Create customised list of menu items to display under "Financial Advisors" dropdown
            generateList(){
                var innerDataList = [];
                            let sitePreferences = Object.values(this.headerUtilityBar.sitePreferences)
                            for(var i = 0; i < sitePreferences.length; i++) {
                    if(sitePreferences[i].dividerNext === 'true') {
                        innerDataList.push(sitePreferences[i]);
                        this.financialAdvDataList.push(innerDataList);
                        innerDataList = [];
                    } else {
                        innerDataList.push(sitePreferences[i]);
                    }
                    //for last element
                    if(i === sitePreferences.length - 1 && innerDataList.length > 0) {
                        this.financialAdvDataList.push(innerDataList);
                    }
                }
            },
            //Read cookie to get previously selected preferred site option
            getPreferredSite(){
                let configCookie = this.$cookies ? this.$cookies.get('_vgi_config') : null;
                if(configCookie){
                    let cookieArray = configCookie.split(';');
                    for(let i = 0;i<cookieArray.length;i++){
                        if(cookieArray[i].startsWith('sp')){
                            this.preferredSite = cookieArray[i][3];
                        }
                    }
                }
            },
            updateCookieValue(value) {
                let date, expires = '', days = 2;
                date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = date.toGMTString();
                this.$cookies.set('_vgi_config',value, expires, '/', '.vanguard.com');
            },
            setDefaultCookie(){
                if(this.$cookies.isKey('_vgi_config')) {
                    let indexOfSp;
                    let vgiConfigCookie = this.$cookies.get('_vgi_config');
                    if(vgiConfigCookie && vgiConfigCookie.indexOf('sp') < 0) {
                        indexOfSp = vgiConfigCookie.length;
                        vgiConfigCookie = vgiConfigCookie.split('');
                        vgiConfigCookie[indexOfSp] = 'sp:F;';
                        this.updateCookieValue(vgiConfigCookie.join(''));
                    }
                } else {
                    this.updateCookieValue('sp:F;');
                }
            },
            setCertificationCookie(value) {
                let date, expires = '', days = 365;
                date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = date.toGMTString();
                this.$cookies.set('cert_type', value, expires, '/', '.vanguard.com');
                EventBus.$emit("Certification-Cookie-Created");
            },
            openAdvisorCertification() {
                let process = {
                    newPageView: "false",
                    processType: "advisor certification"
                }
                let certCookie = this.$cookies ? this.$cookies.get('cert_type') : undefined;
                if (!certCookie && !this.isLoggedOn) {
                    EventBus.$emit("modal:set", AdvisorCertification);
                    EventBus.$emit('modal:set-title', 'Advisor Certification');
                    EventBus.$emit("modal:set-data", {content: this.headerUtilityBar.advisorCertificationContent.advisorcertificationcontent});
                    EventBus.$emit("modal:set-size", "small");
                   // EventBus.$emit("modal:set-fixed-header-footer", true);
                    EventBus.$emit("modal:show");
                    adobeLaunch.trackEvent("processStart", "process", process);
                }
            },
            openAdvisorIdentificationLayer(){
                let process = {
                    newPageView: "false",
                    processType: "enhanced advisor certification"
                };
                let contentData = this.headerUtilityBar.advancedCertificationLayer.advancedcertificationlayer;
                contentData.isSmall = true;
                contentData.inputSortInColumns = false;
                contentData.errorPlaceholderSpace = +contentData.errorPlaceholderSpace;
                let self = this;
                contentData.inputs = Object.values(contentData.inputs);
                contentData = this.reformatData(contentData);
                contentData.inputsHtml= "";
                contentData.headingHtml= "This site is for financial professionals only.";
                    contentData.inputs.forEach(input=>{
                        if(input.id === 'first'){
                            input.requirementsRules = self.inputs.first;
                        }else if(input.id === 'last'){
                            input.requirementsRules = self.inputs.last;
                        }else if(input.id === 'email'){
                            input.requirementsRules = self.inputs.email;
                        }else{
                            delete  input.optionsList;
                        }
                        input.fullWidth = true;
                        input.requirementsHeading = "";
                        this.reformatData(input);
                    });
                let certCookie = this.$cookies && this.$cookies.get('cert_type') && this.$cookies.get('cert_type').cert_cookie === 'ct:ac;'? true : undefined;
                if (!certCookie && !this.isLoggedOn) {
                    self.isAdvancedCertificationLayerOpened = true;
                    EventBus.$emit("modal:set", AdvisorCertification);
                    EventBus.$emit('modal:set-title', 'Advisor Certification');
                    EventBus.$emit("modal:set-data", {content: contentData});
                    EventBus.$emit("modal:show");
                    EventBus.$emit("modal:set-size", "small");
                    adobeLaunch.trackEvent("processStart", "process", process);
                }
            },
            reformatData(contentData){
                for(let obj in contentData){
                        if(contentData[obj] === 'true' || contentData[obj] === 'false') {
                            contentData[obj] = JSON.parse(contentData[obj])
                        }
                }
                return contentData;
            },
            submitAdvisorCertification(isCertified) {
                //Convention for cookie value
                //No Certification - nc, Basic Certification - bc, Advanced Certification - ac, Full Logon - fl
                this.setCertificationCookie('ct:bc;');
                this.userIdentifiedInBasicCertLayer = true;
                const event = new Event('certificationAccepted');
                window.dispatchEvent(event);
                let process = {
                    newPageView: "false",
                    processType: "advisor certification"
                }
                adobeLaunch.trackEvent("processComplete", "process", process);
            },
            async submitAdvancedAdvisorCertification(userData) {
                //Convention for cookie value
                //No Certification - nc, Basic Certification - bc, Advanced Certification - ac, Full Logon - f
                let cookieValue = {
                    cert_cookie : 'ct:ac;',
                    crd : [],
                    email : userData.email
                };
                this.userIdentified = true;
                delete  userData['financial-advisor-confirmation'];
                this.isAdvancedCertificationLayerOpened = false;
                let postUserData = {
                    firstName:userData.first,
                    lastName:userData.last,
                    firmName:userData.firm,
                    emailId:userData.email,
                    phoneNumber:"0000000000"
                };
                let process = {
                    newPageView: "false",
                    processType: "enhanced advisor certification"
                };
                let crd = await advancedCertificationService.advancedCertification(postUserData, this.consumerID);
                let firmCRD;
                let repCRD;
                let certificationEvent;
                if(!crd) {
                    EventBus.$emit("Certification-failed");
                    certificationEvent = new CustomEvent("Certification-failed");
                    adobeLaunch.trackEvent("processAbandoned", "process", process);
                    window.dispatchEvent(certificationEvent)
                }
                else if (crd === 'No match CRD found') {
                  cookieValue.crd = [];
                  certificationEvent = new CustomEvent("Certification-Cookie-Created");
                  this.setCertificationCookie(cookieValue);
                  adobeLaunch.trackEvent("processComplete", "process", process);
                  window.dispatchEvent(certificationEvent);
                }
                else {
                  cookieValue.crd = crd;
                  certificationEvent = new CustomEvent("Certification-Cookie-Created");
                  this.setCertificationCookie(cookieValue);
                  let userDiscovery = {
                      firmCRD: crd[0].firmCRD,
                      repCRD: crd[0].repCRD
                  };
                  adobeLaunch.trackEvent("userDiscoveryData", "userDiscovery", userDiscovery);
                  adobeLaunch.trackEvent("processComplete", "process", process);
                  setTimeout(()=>{window.dispatchEvent(certificationEvent);},700);
                }

            },
            getSuggestedFirms(firmKey) {
                this.suggestedFirmsList = [];
                for (let index = 0; index < this.firmList.length && this.suggestedFirmsList.length < 5; index++) {
                    if (this.firmList[index].toLowerCase().indexOf(firmKey.toLowerCase()) > -1) {
                        this.suggestedFirmsList.push(this.firmList[index]);
                    }
                }
                return this.suggestedFirmsList;
            },
            firmTyping(firmInputText) {
                if (firmInputText && firmInputText.length > 1) {
                    this.getSuggestedFirms(firmInputText);
                } else {
                    this.suggestedFirmsList = [];
                }
            },
            searchSelectChanged(obj)  {
                this.firmTyping(obj.value);
                let optionsListArray = this.suggestedFirmsList;
                if(optionsListArray.length && optionsListArray.length <= 1 && optionsListArray[0].length === obj.value.length) {
                    optionsListArray = [];
                }
                EventBus.$emit("advisor:search-select-options-list-update", {
                    id: obj.id,
                    optionsList: optionsListArray
                });
            },
            closeAdvisorCertification(isCertified) {
                const event = new Event('certificationAbandoned');
                window.dispatchEvent(event);
                let processTypeValue = this.isAdvancedCertificationLayerOpened ? "enhanced advisor certification" : "advisor certification";
                let process = {
                    newPageView: "false",
                    processType: processTypeValue,
                    processStep: "certification start"
                };
                adobeLaunch.trackEvent("processAbandoned", "process", process);
                if(this.isAdvancedCertificationLayerOpened){
                    this.isAdvancedCertificationLayerOpened = false;
                    return;
                }
                const vm = this;
                setTimeout( function() {
                    if(document.referrer === "") {
                        return window.location.assign("/insights/all");
                    } else {
                        vm.validateReferrerUrlAndRedirect();
                    }
                },800);
            },
            validateReferrerUrlAndRedirect(){
                const referrerUrl = document.referrer;
                if(referrerUrl.indexOf(".vanguard.com") > -1){
                    return window.location.assign(referrerUrl);
                } else {
                    return window.location.assign("/insights/all");
                }
            },
            checkRegisterPage(){
                return window.location.pathname.split('/').includes('register')
            },
            checkInternal(){
                const curLoc=window.location.host;
                return curLoc.indexOf("fasi.vanguard.com")>-1;
            }
        },

        async mounted() {
            window.addEventListener('click', this.clickListener);
            this.firmList = await firmListService.getFirmList();
            let self = this;

            window.notifyGlobalHeader = {
                updateShoppingCart : function(orderQuantity){
                    self.literature= orderQuantity;
                },
                displayCertificationLayer : function () {
                    if(!self.userIdentified) {
                        setTimeout(function () {
                            self.openAdvisorCertification();
                        }, 500);
                    }
                },
                displayAdvancedCertificationLayer : function (consumerID = "FLK") {
                    //default value is FLK
                    self.consumerID = consumerID;
                    setTimeout( function() {
                        self.openAdvisorIdentificationLayer();
                    },500);
                },
                openLogonLayer : async function(target = "") {
                    if (window.location.host.indexOf("fasi.vanguard.com") > -1) {
                        const secureURL = "/flk-fas-globalnavigation-webapp/protected/login/redir?url=";
                        adobeLaunch.trackSignInClick();
                        const targetUrl = target === "" ? window.location.pathname : target;
                        return window.location.assign(secureURL + targetUrl);
                    } else {
                        await self.onAuth0LinkClick(undefined, target);
                    }
                },
                isUserCertified: function(){
                    return !!(self.userIdentified || (self.$cookies && self.$cookies.get('cert_type') && self.$cookies.get('cert_type').cert_cookie === 'ct:ac;') || self.isLoggedOn);
                },
                isUserCertifiedInBasicCertLayer: function(){
                    return !!(self.userIdentifiedInBasicCertLayer || window.notifyGlobalHeader.isUserCertified() || (self.$cookies && self.$cookies.get('cert_type') && self.$cookies.get('cert_type') === 'ct:bc;'));
                }
            };
            window.updateCartBasedOnCookie = function () {
                self.literature = this.$cookies.get('LIT_ORDER_COOKIE') ? this.$cookies.get('LIT_ORDER_COOKIE').LIT_QUANTITY : null;
            };

            this.generateList();
            this.isInternal();

            if (this.$cookies){
                this.literature = this.$cookies.get('LIT_ORDER_COOKIE') ? this.$cookies.get('LIT_ORDER_COOKIE').LIT_QUANTITY : null;
            }
            this.cartAriaLabel = this.ariaLables.cartWith + " " + this.literature + " " + this.ariaLables.items;

            EventBus.$on('modal:hidden', () => {
                self.$refs.trigger.focus();
            });
            EventBus.$emit('global-toolbar-mounted',true);
            let event = new Event('global-toolbar-mounted');
            window.dispatchEvent(event);
        },
        created() {
        this.headerUtilityBar.myProfile.myprofile.items = Object.values(this.headerUtilityBar.myProfile.myprofile.items)
            EventBus.$on("advisor:submit-clicked", this.submitAdvisorCertification);
            EventBus.$on("advisor:form-submitted", this.submitAdvancedAdvisorCertification);
            EventBus.$on("advisor:close-clicked", this.closeAdvisorCertification);
            EventBus.$on("advisor:esc-key-pressed", this.closeAdvisorCertification);
            EventBus.$on("advisor:background-clicked", this.closeAdvisorCertification);
            EventBus.$on("advisor:search-select-change", this.searchSelectChanged);
        },
        beforeDestroy() {
            EventBus.$off("advisor:submit-clicked", this.submitAdvisorCertification);
            EventBus.$off("advisor:form-submitted", this.submitAdvancedAdvisorCertification);
            EventBus.$off("advisor:close-clicked", this.closeAdvisorCertification);
            EventBus.$off("advisor:esc-key-pressed", this.closeAdvisorCertification);
            EventBus.$off("advisor:background-clicked", this.closeAdvisorCertification);
            EventBus.$off("advisor:search-select-change", this.searchSelectChanged);
        }

    };
</script>

<style lang="scss">
    .global-header {

        .global-toolbar {
            font-size: 12px;
            position: relative;

            .toolbar-items {
                justify-content: space-between;
            }

            .toolbar-link,
            .cart-link {
                display: inline-block;
                padding: 8px 0;
                width: max-content;

                @include from-md {
                    padding: 14px 0;
                }
                @include hover {
                    text-decoration: none;
                }

                & {
                    margin-left: 24px;
                }
            }

            .toolbar-link {
                @include hover {
                    text-decoration: underline;
                }
            }

            .toolbar-dropdown-trigger {
                color: $color--almost-black;
                padding-top: 13px;
                white-space: nowrap;

                @include from-lg {
                    padding-top: 17px;
                }

                @include from-lg {
                    &.--active {
                        .icon-bold-down {
                            transform: rotateX(180deg) translateY(-1px);
                        }
                    }
                }

                .icon-bold-down {
                    color: $color--red;
                    font-size: 12px;
                    margin-left: 8px;
                    transform: translateY(1px);
                    transform-origin: center;
                    transition: transform 0.25s ease;
                }

                @include hover {
                    color: $color--almost-black;
                    text-decoration: none;
                }
            }

            .toolbar-right {
                text-align: right;

                &.--hide-section {
                    visibility: hidden;
                }

                a {
                    color: $color--almost-black;
                }
            }

            // Shopping cart count indicator
            .toolbar-badge {
                background: $color--red;
                border-radius: 20px;
                color: $color--white;
                display: inline-block;
                font-size: 11px;
                font-weight: $font-weight--bold;
                height: 16px;
                line-height: 18px;
                margin-right: 4px;
                min-width: 16px;
                text-align: center;
            }

            // Cart icon
            .toolbar-cart {
                font-size: 18px;
                vertical-align: text-bottom;
            }

            // Dropdown panel
            .toolbar-dropdown {
                display: none;
                left: 0;
                position: absolute;
                top: calc(100% - 20px);
                width: 320px;

                @include to-lg() {
                    height: 100vh;
                    left: -23px;
                    right: -23px;
                    top: 0;
                    width: auto;
                }

                &.--active {
                    display: block;
                    z-index: 8;
                }

                ul,
                li {
                    list-style: none;
                    margin: 0;
                    font-size: 15px;
                }
            }

            // This borrows a lot of styling from .tooltip-arrow in ui.scss
            .dropdown-arrow {
                left: 3px;
                top: -2px;
                transform: rotate(180deg) scale(0.8);

                &::after {
                    background: $color--bg-gray;
                }
            }

            .toolbar-dropdown-contents {
                background: $color--white;

                @include to-lg() {
                    height: 100%;
                    padding-top: 100px; // room for a close button
                }

                // This is styled differently on small screens; looks less modal
                @include from-lg() {
                    box-shadow: 0 1px 12px 1px rgba(0, 0, 0, 0.2);
                    margin-top: 20px; // space for the arrow
                }
            }

            // Dropdown links group 1: different Vanguard sites
            .toolbar-site-options {

                @include from-lg() {
                    background: $color--bg-gray;
                    border-bottom: 2px solid $color--lighter-gray;
                }

                padding: 0 30px;
            }

            .toolbar-site-option {
                border-bottom: 2px solid $color--lighter-gray;
                font-size: 15px;
                position: relative;

                &:last-child {
                    border-bottom: 0;
                }

                &.--current {
                    &::before {
                        background: $color--red;
                        content: '';
                        display: block;
                        height: 100%;
                        left: -30px;
                        position: absolute;
                        top: 0;
                        width: 4px;
                    }
                }

                a,
                .cta {
                    padding: 20px 0 16px;
                }

                .icon {
                    vertical-align: middle;
                }
            }
        }
        .ie-focusable-only{
            margin-top: 2px;
            padding-left: 2px;
            margin-bottom: 2px;
            padding-right: 2px;
        }
        // Dropdown link groups #2 and #3 - more links
        .toolbar-site-options,
        .toolbar-dropdown-links {
            color: $color--almost-black;
            font-size: 15px;

            a,
            .cta {
                display: block;
                font-size: inherit;
                line-height: 20px;

                @include hover {
                    text-decoration: underline;
                }
            }

            a:not(.cta) {
                color: $color--almost-black;
            }
        }

        .toolbar-dropdown-links {
            padding: 15px 20px 0;
            margin: 0;

            @include from-lg() {
                padding: 15px 30px;
            }

            &:nth-of-type(2) {

                @include from-lg() {
                    padding-bottom: 0;
                }

                li:last-child {
                    border-bottom: 2px solid $color--lighter-gray;
                    padding-bottom: 15px;
                }
            }
        }

        .toolbar-dropdown-link {
            font-size: inherit;

            a {
                padding: 15px 0;
            }
        }

        .linkIndex {
            z-index: 8;
        }
    }

    .ie-focusable:focus-visible{
        outline: -webkit-focus-ring-color auto 1px !important;
    }

    // NGA and Monolith overrides
    .global-header {

        .global-toolbar {

            a {
                border-bottom: 0;
                text-decoration: none;

                &:hover {
                    color: inherit;
                    border-bottom: 0;
                    text-decoration: underline;
                }

                .cta__text {
                    color: $color--red;
                }
            }
        }

        .--no-pointer-events {
            pointer-events: none;
        }
    }

    .fas-core {

        .site-preference {

            &-option {

                input {

                    &,
                    &:focus {
                        margin-top: 0;
                        -webkit-appearance: none;
                    }

                    &:focus {
                        border-width: 2px !important; // !important required to override 'logonLayer.css' !important declaration
                        border-style: solid !important; // !important required to override 'logonLayer.css' !important declaration
                    }
                }
            }
        }
    }
    .large-view{
        @include to-bp(1024px){
            display:none !important;
        }
    }
    body.vg-is-tabbing {
        .input-search-select.has-focus {
            input.input-text {
                outline: #232323 !important;
                border-color: #232323 !important;
            }
        }
    }
</style>
