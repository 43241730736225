export default class GlobalSearchDataFilter {

	constructor() {
		this.numberOfResearch = 0;
		this.numberOfNews = 0;
		this.litID = "";
		this.fundID = "";
		this.fundIdentifier = "";
		this.term = "";
		this.query = "";
		this.origin = "";
	}
	getFullText(text) {
		let pattern = /\{\{[a-zA-Z]*\}\}/g;
		let variables = text.match(pattern);
		for (let i = 0; i < variables.length; i++) {
			let currentVariable = variables[i].replace('{{', '').replace('}}', '');
			text = text.replace(variables[i], this[currentVariable]);
		}
		return text;
	}
	getOrigin() {
		let start;
		let queryStr;
		let end;
		let referrer = document.referrer;
		if (referrer !== null && referrer.length !== 0) {
			start = referrer.lastIndexOf('/') + 1;
			queryStr = referrer.indexOf('?');
			if (queryStr >= 0) {
				end = queryStr;
			} else {
				end = referrer.length;
			}
			if (end >= start) {
				this.origin = referrer.substring(start, end);
			}
		}
		return this.origin;
	}
	getFilteredResults(searchData, searchContent, searchQuery, isLoggedOn) {
		this.query = searchQuery;
		let filteredSearchResults = {
			all: {
				link: "",
				text: ""
			},
			news: {
				link: "",
				text: ""
			},
			research: {
				link: "",
				text: ""
			},
			suggestions: []
		};
		this.getOrigin();
		for (let i = 0; i < searchData.results.length; i++) {
			let fundData = {
				fundID: "",
				fundIdentifier: "",
				tickerSymbol: "",
				term: "",
				litID: "",
				type: "",
				isFund: true,
				pdpLink: "",
				factSheetLink: "",
				termLink: ""
			};
			this.litID = searchData.results[i].litID;
			this.fundID = searchData.results[i].fundID;
			this.fundIdentifier = searchData.results[i].tickerSymbol && searchData.results[i].tickerSymbol !== " "
				? searchData.results[i].tickerSymbol
				: searchData.results[i].fundID;
			this.term = searchData.results[i].term;
			fundData.fundID = searchData.results[i].fundID;
			fundData.fundIdentifier = searchData.results[i].tickerSymbol && searchData.results[i].tickerSymbol !== " "
				? searchData.results[i].tickerSymbol
				: searchData.results[i].fundID;
			fundData.litID = searchData.results[i].litID;
			fundData.type = searchData.results[i].type;
			fundData.tickerSymbol = searchData.results[i].tickerSymbol;
			fundData.term = searchData.results[i].term;
			if (fundData.type === 'term') {
				fundData.isFund = false;
				fundData.termLink = this.getFullText((isLoggedOn ? searchContent.secureTermLandingPageURL : searchContent.unSecureTermLandingPageURL).replace(/&amp;/g, '&'));
			}
			fundData.pdpLink = this.getFullText((isLoggedOn ? searchContent.secureFundLandingPageURL : searchContent.unSecureFundLandingPageURL).replace(/&amp;/g, '&'));
			fundData.factSheetLink = searchData.results[i].factsheetUrl;
			filteredSearchResults.suggestions.push(fundData);
		}
		this.numberOfResearch = searchData.numberOfResearch;
		this.numberOfNews = searchData.numberofNews;
		filteredSearchResults.research.link = this.getFullText((isLoggedOn ? searchContent.secureSearchResearchCommentaryURL : searchContent.unSecureSearchResearchCommentaryURL).replace(/&amp;/g, '&'));
		filteredSearchResults.research.text = this.getFullText(searchContent.research);
		filteredSearchResults.news.link = this.getFullText((isLoggedOn ? searchContent.secureSearchNewsURL : searchContent.unSecureSearchNewsURL).replace(/&amp;/g, '&'));
		filteredSearchResults.news.text = this.getFullText(searchContent.news);
		filteredSearchResults.all.link = this.getFullText((isLoggedOn ? searchContent.secureSearchAllURL : searchContent.unSecureSearchAllURL).replace(/&amp;/g, '&'));
		filteredSearchResults.all.text = this.getFullText(searchContent.all);

		return filteredSearchResults;
	}
}
