import axios from 'axios';
import EnvConfigService from "@/js/services/envConfig.service";
import logger from "@/js/services/logger.service";
let ENDPOINTS = require('../../config/endpoints.constant');

let searchService = {};

searchService.getSearchData = async(fundId) => {
    let searchQueryParams = ENDPOINTS.SEARCH_QUERY_PARAMS_PUBLIC
    let pageConfig = await EnvConfigService.get();
    searchService.searchQuery = pageConfig.advisorDomain + ENDPOINTS.SEARCH_SERVICE_PATH + searchQueryParams;
    return axios.get(searchService.searchQuery + fundId)
        .catch(error => {
            logger.log('globalnavigation-webapp: Error in searchService.js while retrieving search results ' + JSON.stringify(error));
            console.error(error);
        });
};

export default searchService;
