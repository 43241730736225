import axios from "axios";
import EnvConfigService from '@/js/services/envConfig.service';

let ENDPOINTS = require('../../../config/endpoints.constant');

/*
 * Gets different  Firm List Data that a Participant may belong to.
 * The firmListUrl invokes the firm-list.controller.ts
 *  */
let getFirmList = async () => {
  let firmList = [];
  const domainSiteUrl = await EnvConfigService.checkSiteInternalOrExternal();
  let firmListUrl = domainSiteUrl + ENDPOINTS.FIRM_LIST;
  try {
    const firmListData = await axios.get(`${firmListUrl}`);
    firmList = firmListData.data;
  } catch (error) {
    console.log(
      `Error occurred while trying to retrieve ${firmListUrl} data with error: ${error}`
    );
  }
  return firmList;
};

export default {
  getFirmList
};
