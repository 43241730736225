import axios from "axios";
import EnvConfigService from '@/js/services/envConfig.service';
let ENDPOINTS = require('../../../config/endpoints.constant');

let salesForceData = async (salesForcePostData) => {
  const domainSiteUrl = await EnvConfigService.checkSiteInternalOrExternal();
  const uri = domainSiteUrl + ENDPOINTS.SALES_FORCE_NEWS_LETTER;
  const response = await axios.put(uri, salesForcePostData).catch((err) => {
    console.log("salesForce call failed:  " + JSON.stringify(err));
    return {
      data: {
        errorOccurred: true
      }
    };
  });
  return response.data;
};

export default {
  salesForceData
};
