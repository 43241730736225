import axios from 'axios';
import EnvConfigService from "@/js/services/envConfig.service";
import logger from "@/js/services/logger.service";
let ENDPOINTS = require('../../config/endpoints.constant');

let getFrapieData = async() => {
    let vanguardFunds = {};
    try {
        vanguardFunds = await axios.get(EnvConfigService.buildWebappRegionURL() + ENDPOINTS.VANGUARD_FUNDS);
    } catch (error) {
        logger.log('globalnavigation-webapp: Error in frapieService.js while fetching funds data ' + JSON.stringify(error));
    }
    return vanguardFunds.data;
};

export default {
    getFrapieData
}


