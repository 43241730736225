<template>
    <header
      :class="['fas-core', 'global-header', { 'global-header--sticky': sticky }]"
      :style="topPosition ? `margin-top: -${topPosition}px` : ''"
      v-if="navData"
      ref="globalHeader"
    >
      <div>
        <a class="skip-to-main" href="javascript:;" tabindex="0" role="link" @click="(event) => handleSkipClick(event)">Skip to main content</a>
      </div>
      <div class="ie-banner">
        <v-breakpoint v-model="model"></v-breakpoint>
        <EmergencyAlert
          :content="navData.commoncontent.commonContent.emergencyiealert"
          v-if="showIEAlert"
        />
      </div>
      <GlobalToolbar
        :globalNAVData="navData"
        :isLoggedOn="isLoggedOn"
        :fullHeader="fullHeader"
      />
      <GlobalNavLarge
        v-if="model.isLarge"
        :globalNAVData="navData"
        :isLoggedOn="isLoggedOn"
        :fullHeader="fullHeader"
        :isSearchPage="searchPage"
        :afterCoveoInit="afterCoveoInit"
        :toggleCoveoSearch="toggleCoveoSearch"
        :scriptsLoaded="scriptsLoaded"
      >
      </GlobalNavLarge>
      <GlobalNavSmall
        v-else-if="!model.isLarge"
        :globalNAVData="navData"
        :isLoggedOn="isLoggedOn"
        :fullHeader="fullHeader"
        :isSearchPage="searchPage"
        :afterCoveoInit="afterCoveoInit"
        :toggleCoveoSearch="toggleCoveoSearch"
        :scriptsLoaded="scriptsLoaded"
      >
      </GlobalNavSmall>
      <CoveoSearchbox
        v-show="toggleCoveoSearch && isSearchOpen"
        :content="navData"
        :searchPage="searchPage"
        :coveoConfigs="coveoConfigs"
        :class="[
          model.isLarge ? 'search-panel-large' : 'search-panel-small',
          showCoveoSearchBar ? 'show-coveo-search' : 'hide-coveo-search'
        ]"
      />
      <Modal v-if="fullHeader" />
      <EmergencyAlert
        :content="navData.commoncontent.commonContent.emergencyalert"
        v-if="showAlert"
      />
      <div class="holdingsSearch-banner">
        <EmergencyAlert
          :content="
            isHoldingsSearch
              ? navData.commoncontent.commonContent
                  .emergencyalertforholdingssearch
              : navData.commoncontent.commonContent.emergencyalertforliterature
          "
          v-if="isHoldingsSearch || isLiterature"
        />
      </div>
      <div class="crc-banner">
        <EmergencyAlert
          :content="navData.commoncontent.commonContent.emergencyalertforcrcpages"
          v-if="isCRCPage"
        />
      </div>
    </header>
</template>

<script>
import { VBreakpoint, Model, EventBus } from "@vg-faset/core";
import GlobalNavLarge from "./components/GlobalNavLarge.vue";
import GlobalNavSmall from "./components/GlobalNavSmall.vue";
import GlobalToolbar from "./components/GlobalToolbar.vue";
import ContentService from "./services/contentService.js";
import Modal from "@vg-faset/ui/src/components/modal/Modal";
import axios from "axios";
import EnvConfigService from "@/js/services/envConfig.service";
import Vue from "vue";
import VueCookies from "vue-cookies";
import NGAMonolithHack from "./mixins/ngaMonolithHack";
import logger from "@/js/services/logger.service";
import EmergencyAlert from "@/components/EmergencyAlert/EmergencyAlert";
import CoveoSearchbox from "./components/GlobalNav/CoveoSearchBox";
import CoveoService from "@/js/services/coveo.service";
Vue.use(VueCookies);

let ENDPOINTS = require("../config/endpoints.constant");

export default {
    name: "GlobalHeader",
    components: {
        VBreakpoint,
        GlobalNavLarge,
        GlobalToolbar,
        GlobalNavSmall,
        Modal,
        EmergencyAlert,
        CoveoSearchbox
    },
    props: {
        sticky: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            model: new Model(),
            navData: null,
            isLoggedOn: false,
            isInternalSite: window.location.host.indexOf("fasi.vanguard.com") > -1,
            searchPage: false,
            coveoScriptsLoaded: false,
            afterCoveoInit: false,
            isSearchOpen: false,
            coveoConfigs: {}
        };
    },
    mixins: [NGAMonolithHack],
    computed: {
        toggleCoveoSearch() {
            return this.navData.searchutilitybar.toggleCoveoSearch;
        },
        showAlert() {
            return (
                this.navData &&
                this.navData.commoncontent.commonContent.emergencyalert &&
                this.navData.commoncontent.commonContent.emergencyalert
                    .hasEmergencyAlert &&
                !this.URLInContentHideList() &&
                !this.bannerPreviouslyClosed()
            );
        },
        showIEAlert() {
            return (
                this.navData &&
                this.navData.commoncontent.commonContent.emergencyiealert
                    .hasEmergencyIEAlert &&
                this.checkIfBrowserIsIE()
            );
        },
        scriptsLoaded() {
            return this.coveoScriptsLoaded;
        },
        showCoveoSearchBar() {
            if (this.toggleCoveoSearch) {
                if (this.isSearchOpen) {
                    return true;
                }
                return false;
            }
            return false;
        },
        isHoldingsSearch() {
            const curLoc = window.location.pathname;
            return curLoc.indexOf("/analysistools/holdingssearch") > -1;
        },
        isLiterature() {
            const curLoc = window.location.pathname;
            let litPaths = [
                "literatureforms/orderhistory",
                "literatureforms/literature",
                "literatureforms/fundsEtfs",
                "literatureforms/forms"
            ];
            return (
                this.isInternalSite &&
                litPaths.find(path => curLoc.indexOf(path) > -1) !== undefined
            );
        },
        isCRCPage() {
            const curLoc = window.location.pathname;
            let crcPaths = [
                "/crc/clientExpectations",
                "/crc/clientExpectationsBuilder"
            ];
            return crcPaths.some(path => curLoc.indexOf(path) > -1);
        }
    },
    methods: {
        async getNavData() {
                this.navData = await ContentService.get().catch((error) => {
                  logger.log(
                    "globalnavigation-webapp: Error in GlobalHeader.vue while retrieving global nav content " +
                    JSON.stringify(error)
                  );
                  console.error(error);
                  return null;
                });
        },
        async getLoggedOnStatus() {
            try {
                const response = await axios.get(
                    EnvConfigService.buildWebappRegionURL() + ENDPOINTS.GET_SMSESSION
                );
                this.isLoggedOn = response.data.isLoggedOn;
            } catch (error) {
                this.isLoggedOn = false;
                logger.log(
                    "globalnavigation-webapp: Error in GlobalHeader.vue while retrieving logged on status " +
                    JSON.stringify(error)
                );
                console.error(error);
            }
        },
        URLInContentHideList() {
            let content = this.navData.commoncontent.commonContent.emergencyAlert;
            let pageArray = content.hiddenPages.split(",");
            pageArray = pageArray.map(entry => entry.trim());
            return pageArray.includes(location.pathname);
        },
        bannerPreviouslyClosed() {
            return sessionStorage.getItem("bannerClosed") === "true";
        },
        checkIfBrowserIsIE() {
            return /msie\s|trident\//i.test(window.navigator.userAgent); // (navigator.userAgent.indexOf("MSIE") != -1 );
        },
        handleSkipClick(e) {
            const mainContent = document.getElementById("main-content");
            
            if (mainContent) {
                if(!mainContent.hasAttribute("tabindex")) {
                  mainContent.setAttribute("tabindex", "-1");
                }

                mainContent.focus();
            }

            e.stopPropagation();
            e.preventDefault();
        }
    },

  async created() {
    this.getNavData();
    this.getLoggedOnStatus();
    const pathName = window.location.pathname;
    EventBus.$on("after-coveo-init", () => {
      this.afterCoveoInit = true;
    });
    this.searchPage =
      pathName === ENDPOINTS.COVEO_SEARCH_RESULTS_URL ||
      pathName.indexOf("/web/ecs/fus-search-results-coveo-webapp") !== -1 ||
      pathName === ENDPOINTS.SEARCH_RESULTS_URL ||
      pathName === "/web/ecs/fss-fas-search-results-redesign/current/";
    EventBus.$on("open-coveo-search", e => {
      this.isSearchOpen = e;
    });
    this.coveoConfigs = await CoveoService.getCoveoSecureToken();
    //Load all coveo scripts
    let pageConfig = await EnvConfigService.get();
    if (!this.searchPage) {
      const jsSearch = document.createElement("script");
      jsSearch.setAttribute("src", pageConfig.coveoScripts.coveoSearchJS);
      jsSearch.setAttribute("async", "false");
      const coveoSearchCss = document.createElement("link");
      coveoSearchCss.setAttribute(
        "href",
        pageConfig.coveoScripts.coveoSearchCSS
      );
      coveoSearchCss.setAttribute("rel", "stylesheet");
      coveoSearchCss.setAttribute("type", "text/css");

      let retryCount = 0;
      const maxRetryAmount = 5;

      const retry = setInterval(() => {
        jsSearch.onload = () => {
          clearInterval(retry);
          EventBus.$emit("coveo-scripts-loaded");

          setTimeout(() => {
            this.coveoScriptsLoaded = true;
          }, 1000);
        };

        jsSearch.onerror = () => {
          document.head.removeChild(coveoSearchCss);
          document.head.removeChild(jsSearch);
        };

        document.head.appendChild(coveoSearchCss);
        document.head.appendChild(jsSearch);

        if (retryCount >= maxRetryAmount) clearInterval(retry); // stop trying after 5 attempts
        retryCount++;
      }, 1000);
    }
    window.addEventListener("global-toolbar-mounted", function() {});
  }
};
</script>

<style lang="scss">
$url: get("url");
@import "./styles/fonts.scss";

$font-family: "Univers LT Std", Arial, sans-serif;

@import "../node_modules/@vg-faset/core/styles/shared/smart-focus";

//skip to content link
.skip-to-main {
  position: absolute;
  top: 16px;
  opacity: 0;
  cursor: default;

  &:focus {
    opacity: 1;
  }
}

// Core Imports under namespace
.fas-core {
  @import "../node_modules/@vg-faset/core/styles/shared/variables";
  @import "../node_modules/@vg-faset/core/styles/shared/type";
  @import "../node_modules/@vg-faset/core/styles/shared/ui";
  @import "../node_modules/@vg-faset/core/styles/shared/forms";
  @import "../node_modules/@vg-faset/core/styles/shared/grid";
  @import "../node_modules/@vg-faset/core/styles/shared/transitions";

  color: #232323;
  font-size: 16px;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;

  &footer {
    font-family: $font-family;
  }

  &.footer {
    text-align: initial;
  }

  .modal {
    display: block;
    position: relative;
    top: initial;
    right: initial;
    bottom: initial;
    left: initial;
  }

  *:focus {
    outline-width: 0;
  }

  .vg-is-tabbing & {
    *:focus {
      outline-width: initial;
    }
  }
  .input-radio input {
    -webkit-appearance: none;
  }

  .button {
    -webkit-appearance: none;
    height: initial;
  }
}

.global-header {
  .advisor-certification {
    .input-check {
      align-items: flex-start;
      margin-top: 16px;
    }
    .cta {
      display: inline;
      text-decoration: underline;
      font-weight: 500;
    }
    p.p3 {
      margin-bottom: 16px;
    }
  }

  background-color: #fff;
  z-index: 200;

  &,
  * {
    box-sizing: border-box;
    font-family: $font-family;
  }

  &--sticky {
    @include from-md {
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }

  .container {
    width: initial;
    padding-right: 0;
    padding-left: 0;
  }
}

// NGA and Monolith overrides
body {
  position: initial;

  .fas-core {
    &.global-header {
      position: absolute;
      right: 0;
      left: 0;
      width: 100%;
    }

    .site-preference-icon {
      line-height: 45px;
    }
  }

  // If body element contains .fas-header-relative, undo above styles and position relative
  &.fas-header-relative {
    .fas-core {
      &.global-header {
        position: relative;
        right: initial;
        left: initial;
      }
    }
  }

  // Required override for the builder pages
  &.fas-builder-page .vuiMain {
    padding: 0 0 0 18px;
  }
}
.hide-coveo-search {
  display: none !important;
}
.show-coveo-search {
  display: block !important;
}
.ie-banner {
  .emergency-alert {
    box-shadow: none;
    &__container {
      padding: 15px 0px;
      margin: 0px auto;
      display: block;
      background-color: #faf2bb;
    }
  }
  .emergency-alert__headline {
    margin-top: 0px;
  }
  .emergency-alert__detail {
    margin: 0px;
    max-width: 1100px;
  }
  .emergency-alert__image {
    max-width: 0px;
  }
  .emergency-alert__content {
    display: block;
  }
  .cta {
    color: #e37222;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1280px) {
  .ie-banner {
    .emergency-alert {
      &__container {
        padding: 15px 20px;
      }
    }
  }
}
button {
  color: $color--neutral-2; // override default blue color for iOS 15+
}
@media (max-width: 769px) {
  .emergency-alert__container_for_Holdings_search {
    padding: 16px 0 16px 0 !important;
    background-color: #fff8e6 !important;
  }
}
@media (min-width: 770px) {
  .emergency-alert__container_for_Holdings_search {
    padding: 16px 0 0 0 !important;
    background-color: #fff8e6 !important;
  }
}
#fas-global-search > div.container.row {
  position: unset !important;
}
</style>