<template>
    <aside role="alert">
      <v-breakpoint v-model="model"></v-breakpoint>
      <div v-if="this.model.isSmall">
          <transition-expand >
              <div v-show="showAlert"
                  class="emergency-alert"
                  :class="[layoutClass, styleClass, content && (content.isHoldingsSearch==='true' ||content.isLiterature==='true' ) ? 'emergency-alert__container_for_Holdings_search' : '']">
                  <span>
                      <AlertContent
                          :content="content"
                          :isSmall="true"
                      />
                      <button class="closeText" v-show= "content.hasEmergencyIEAlert==='false'" v-on:click="closeBanner()" aria-label="close">
                        <Icon type="close"/>
                      </button>
                  </span>
              </div>
          </transition-expand>
      </div>
      <div v-else>
          <transition name="fade">
              <div v-show="showAlert"
                  class="emergency-alert"
                  :style="{ 'background-color': content.bgColor}"
                  :class="[layoutClass, styleClass]">
                  <span>
                      <AlertContent
                          :content="content"
                          :isSmall="false"
                      />
                      <button class="closeText" v-show ="content.hasEmergencyIEAlert==='false'" v-on:click="closeBanner()" aria-label="close">
                        <Icon type="close"/>
                      </button>
                  </span>
              </div>
          </transition>
      </div>
    </aside>
</template>

<style lang="scss" scoped>
    .icon-close {
      font-style: normal;
    }

    .closeText {
      top: 47.5%;
      right: 24px;
      position: absolute;
      border: 0px;
      background: transparent;
      :hover {
      cursor: pointer;
    }
  }

  .emergency-alert {
    background-color: $color--bg-gray;
    box-shadow: 0 2px 4px 0 rgba($color--gray, 0.5);
    position: relative;
    width: 100%;
    z-index: 5;

    &--long {
      @include to-md {
        position: relative;
      }
    }

    &__container {
      padding: 16px 10px;

      @include from-md {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 20px 10px;
      }
    }

    &__content {
      @include from-md {
        align-items: center;
        display: flex;
      }
    }

    &__image {
      @include aspect-ratio(1, 1);

      margin-right: 20px;
      max-width: 44px;

      @include to-md {
        display: none;
      }

      img {
        display: block;
        height: auto;
        width: 100%;
      }
    }

    &__headline,
    &__detail {
      font-size: 14px;
    }

    &__headline {
      margin-bottom: 5px;
    }

    .cta {
      margin-top: 10px;
      white-space: nowrap;

      @include from-md {
        margin-top: 0;
      }
    }
  }

  // Themed style variants
  .emergency-alert--warning {
    background: $color--yellow;
    color: $color--almost-black;
  }

  .emergency-alert--internal,
  .emergency-alert--urgent {
    color: $color--white;
  }

  .emergency-alert--internal {
    background: $color--dark-teal;
  }

  .emergency-alert--urgent {
    background: $color--red;
  }

  // Only the default style uses a red cta
  .emergency-alert--warning,
  .emergency-alert--internal,
  .emergency-alert--urgent {
    .cta {
      color: inherit !important;
    }
  }
</style>

<script>
import { VBreakpoint, Model } from '@vg-faset/core';
import TransitionExpand from '@vg-faset/ui/src/components/transitions/TransitionExpand.vue';
import AlertContent from "@/components/EmergencyAlert/AlertContent";
import { Icon } from '@vg-faset/ui/src/components/icon';
import { EventBus } from "@vg-faset/core";

export default {
    name: "EmergencyAlert",
    components: {AlertContent, TransitionExpand, VBreakpoint, Icon},
    props: {
        content: {
            type: Object
        }
    },
    data() {
        return {
            model: new Model(),
            bannerClosed: false
        }
    },
    computed: {
        layoutClass() {
            return this.content.heading.length > this.content.maxHeadlineChar ||
            this.content.description.length > this.content.maxDetailChar ||
            this.$parent.screenWidth < 350 ||
            this.$parent.screenHeight < 570
                ? 'emergency-alert--long'
                : 'x';
        },
        styleClass() {
            return `emergency-alert--${this.alertStyle}`;
        },
        showAlert() {
          return !this.bannerClosed;
        }
    },
    methods: {
        closeBanner() {
            this.bannerClosed = true;
            sessionStorage.setItem("bannerClosed", "true");
            setTimeout(()=>{
                EventBus.$emit("position-header");
            },400);  
        }
    }
}
</script>
