import axios from 'axios';
import EnvConfigService from '@/js/services/envConfig.service';

let ENDPOINTS = require('../../../config/endpoints.constant');

let self = {};

self.log = async message => {
    self.endpoint = EnvConfigService.buildWebappRegionURL() + ENDPOINTS.LOGGER;
    await axios.post(self.endpoint, {
        message: message
    }).catch((error) => {
        console.error(error);
    });
};

export default self;
