<template>
  <div class="nav-wrapper" @keyup="handleKey">
    <nav class="nav container" role="navigation" :aria-label="globalNavContent.commoncontent.commonContent.arialablesforaccessibility.siteNavigation">
      <div class="box">
        <div class="nav-logo col sm-3" :class="{ '--no-right-border' : !fullHeader }">
          <a :href="addDomainToPath(globalNavContent.commoncontent.commonContent.advisorshome.url)" class="nav-item__link nav-icon-link ie-focusable">
            <img src="../assets/vanguard.svg" alt="Vanguard Home">
          </a>
        </div>

        <div v-if="fullHeader" class="nav-menu col sm-auto">
          <ul class="nav-items box" @click="onNavClick" @focus.capture="updateFocus">
            <template v-for="(silo, index) in globalNavContent.siloscontent.silos">
              <template v-if="silo.displayName !== 'eConnect'">
                <li class="nav-item" :key="index" :data-selected="silo.columns ? index : null">
                  <template v-if="silo.columns">
                    <a href="javascript:void(0)" :id="navMenuId(index)" class="nav-item__link ie-focusable" aria-haspopup="true" aria-expanded="false">
                      <span class="nav-item__link-text" v-html="silo.displayName"></span>
                      <span class="icon icon-down" aria-hidden="true"></span>
                    </a>
                  </template>
                  <template v-else>
                    <a :href="isLoggedOn ? addDomainToPath(silo.secureLink) : addDomainToPath(silo.unSecureLink)" class="nav-item__link ie-focusable" aria-haspopup="true" @click="sendLaunchData" v-html="silo.displayName"></a>
                  </template>
                </li>
              </template>
            </template>
          </ul>
        </div>

        <div v-if="fullHeader" class="nav-search col sm-2">
          <button
                  ref="searchToggle"
                  :title="!searchOpen ? 'Search' : 'Close'"
                  class="search__link button--unstyled button--search ie-focusable"
                  @click="toggleSearch"
                  @keydown="toggleSearch"
                  :aria-pressed="searchOpen.toString()"
                  role="button">
            <span class="visually-hidden">Search</span>
            <span v-if="!searchOpen" class="icon icon-search" aria-hidden="true"></span>
            <span v-else class="icon icon-close" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </nav>
    <SearchPanel
            v-if="!toggleCoveoSearch && searchOpen"
            :class="isSearchPage ? 'search-panel--search' : ''"
            :content="globalNavContent"
            :closePanel="closeSearch"
            :isLoggedOn="isLoggedOn"
            :isInternalSite="isInternalSite"
            v-on="$listeners"
            :isSearchPage="isSearchPage"/>
    <div v-if="scriptsLoaded || isSearchPage">
      <slot v-bind:searchOpen="searchOpen" ></slot>
    </div>
    <div
            v-if="fullHeader"
            class="nav-panel"
            ref="navpanel"
            :aria-label="currentSubnavLabel"
            tabindex="-1"
            role="navigation"
    >
      <div class="container nav-panel-container">
        <SiloLarge :silosContent="silosContent" :sectionsContent="sectionsContent" @tab:out="closePanel" :isLoggedOn="isLoggedOn"></SiloLarge>
      </div>
    </div>
    <div class="nav-overlay" aria-hidden="true" @click="closePanel"></div>
  </div>
</template>

<script>
  import { eStudioHelper } from '../js/mixins';
  import SearchPanel from './GlobalNav/SearchPanel.vue';
  import SiloLarge from './GlobalNav/SiloLargeView.vue';
  import logger from "@/js/services/logger.service";
  import { adobeLaunch } from 'vg-fas-adobe-launch';
  import { EventBus } from "@vg-faset/core";

  export default {
    name: 'GlobalNavLarge',
    mixins: [ eStudioHelper ],
    props: {
      globalNAVData:{
        type: Object
      },
      isLoggedOn: {
        type: Boolean
      },
      fullHeader: {
        type: Boolean,
        default: true
        },
        isSearchPage: {
          type: Boolean,
          default: false
        },
      toggleCoveoSearch: {
        type: Boolean,
        default: true
      },
      scriptsLoaded: {
        type: Boolean,
        default: false
      },
      afterCoveoInit: {
        type: Boolean,
        default: false
      }
    },
    components: {
      SearchPanel,
      SiloLarge
    },
    data() {
      return {
        navItems: [],
        currentSubnavLabel: 'Investments',
        lastFocus: null,
        searchOpen: false,
        globalNavContent: this.globalNAVData,
        currentSubnav: 0,
        silosContent: {},
        sectionsContent: {},
        isInternalSite: window.location.host.indexOf("fasi.vanguard.com") > -1
      };
    },
    methods: {
      navMenuId(index){
        return "navMenu" + index;
      },

      ariaExpanded(isExpanded, navMenuId){
        let navMenuClass = document.querySelectorAll('.nav-item__link');
        for(let i = 0; i < navMenuClass.length; i++){
          navMenuClass[i].setAttribute('aria-expanded', 'false');
        }
        if(navMenuId !== null && document.getElementById(navMenuId) !== null){
          document.getElementById(navMenuId).setAttribute('aria-expanded', isExpanded);
        }
      },

      getClickedElementId(e) {
        let navMenuIdClicked = null;
        if(e && e.target.classList.contains('nav-item__link-text')){
          navMenuIdClicked = e.target.parentElement.id;
        } else if(e && e.target.classList.contains('nav-item__link')){
          navMenuIdClicked = e.target.id;
        }
        return navMenuIdClicked;
      },

      onNavClick(e) {
        let navItem = e.target;
        let navMenuId = this.getClickedElementId(e);

        // Don't respond to whitespace clicks
        if (navItem.nodeName.toLowerCase() === 'ul') {
          return;
        }

        // subnav and search should not appear at the same time so we close it
        this.closeSearch();
        // We need to operate on the parent li and its children, not just the a
        if (navItem.nodeName.toLowerCase() === 'a') {
          navItem = e.target.parentNode;
        }
        if (navItem.classList.contains("nav-item__link-text")) {
          navItem = e.target.parentNode.parentNode;
        }

        // Toggle the active states on the links and panel
        this.navItems.forEach(el => {
          if (el !== navItem) {
            el.classList.remove('--active');
            this.ariaExpanded('false', navMenuId);
          }
        });
        navItem.classList.toggle('--active');

        // Store the link we just clicked so we can return focus
        // if the menu is closed
        this.lastFocus = navItem.querySelector('.nav-item__link');

        // If we're clicking on the subnav that's already open, close it.
        if (
                navItem.getAttribute('data-selected') === this.currentSubnav &&
                this.$refs.navpanel.classList.contains('--active')
        ) {
          this.closePanel(e);
          return;
        }

        if (navItem.hasAttribute('data-selected')) {
          this.currentSubnav = navItem.getAttribute('data-selected');
          // Set the dropdown contents
          this.silosContent = this.globalNavContent.siloscontent.silos[this.currentSubnav];
          // Show and focus the panel
          this.$refs.navpanel.classList.add('--active');
          this.ariaExpanded('true', navMenuId);
          this.$refs.navpanel.focus();

          // Prevent scrolling on the rest of the page
          document.body.classList.add('--nav-open');
          document.body.style.overflowY = 'hidden';
            if (this.isSearchPage) {
              document.querySelector('.nav-overlay').style.display='block';
            }
        } else {
          this.closePanel(e);
        }
        if(document.querySelector('.nav-overlay').style.display==='block'){
          document.querySelector('.nav-overlay').style.display='none';
        }
      },

      closePanel(e) {
        let navMenuId = this.getClickedElementId(e);

        // Deactivate all the nav items
        this.navItems.forEach(el => {
          if (el.classList) {
            el.classList.remove('--active');
            this.ariaExpanded('false', navMenuId);
              // To remain search bar open on search results page
              if (this.isSearchPage) {
                this.openSearch();
              }
          }
        });

        // Close the dropdown panel
        if (this.$refs.navpanel) {
          this.$refs.navpanel.classList.remove('--active');
          this.ariaExpanded('false', navMenuId);
              // To remain search bar open on search results page
              if (this.isSearchPage) {
                this.openSearch();
              }
        }

        // Send focus back to the link that opened the menu
        if (this.lastFocus && this.lastFocus.classList.contains('nav-item__link')) {
          this.lastFocus.focus();
        }

        // reset Last Focus value to null after focus is given
        this.lastFocus = null;

        // Re-enable scrolling on the page
        document.body.classList.remove('--nav-open');
        document.body.style.overflowY = 'auto';

        // because I was calling this.closePanel when openining search and it was
        // being cancelled it out so now I make sure here that search is only closed
        // from a click to the overlay itself
        if (e && e.target.classList.contains('nav-overlay')) {
          if (this.isSearchPage && this.searchOpen) {
            return;
          } else {
            this.closeSearch();
            document.querySelector('.nav-overlay').style.display='none';
          }
        }
        EventBus.$emit("open-coveo-search",this.searchOpen);
        document.querySelector('.nav-overlay').style.height= window.document.documentElement.offsetHeight +'px';
      },

      closeSearch() {
        // Re-enable scrolling on the page
        document.body.classList.remove('--search-open');
        // close search if it's open
        this.searchOpen = false;
        EventBus.$emit("open-coveo-search",this.searchOpen);
      },

      updateFocus(e) {
        if (e.target.classList.contains('nav-item__link')) {
          this.lastFocus = e.target;
        }
      },

      handleKey(e) {
        if (e.key === "Escape") {
          this.closePanel(e);
        }
      },

      toggleSearch(e) {
        if (!this.afterCoveoInit) {
          logger.log("globalnavigation-webapp-coveo: Error getting Coveo scripts")
          const eventName = "coveoSearchError"
          const eventDataName = "error"
          const eventData = { "errorMessage": "Global Nav Coveo Script Error"}
          adobeLaunch.trackEvent(eventName, eventDataName, eventData)
          return;
        }

        if (e.type === 'keydown' && e.key !== "Space") {
          return;
        }

        e.preventDefault();
        this.searchOpen = !this.searchOpen;
        EventBus.$emit("open-coveo-search",this.searchOpen);
        if (this.searchOpen) {
          EventBus.$emit("load-coveo-suggestions");
          this.closePanel(e);
          document.body.classList.add('--search-open');
          this.lastFocus = this.$refs.searchToggle;
          //Set coveo focus on searchbox
          let inputBox = document.querySelector(".magic-box-input input");
          inputBox.value = "";
          setTimeout(()=>{
            inputBox.focus();
          }, 50);
          if (this.isSearchPage) {
            document.body.style.overflow = "auto";
            document.querySelector('.nav-overlay').style.display='none';
            document.querySelector('header').style.position='unset';
            EventBus.$emit("open-coveo-search",this.searchOpen);
            setTimeout(()=>{
              EventBus.$emit("open-search");
            });
          } else {
            document.querySelector('.nav-overlay').style.display='block';
          }
        } else {
          document.body.classList.remove('--search-open');
          this.searchOpen = false;
          document.querySelector('header').style.position='absolute';
          document.querySelector('.nav-overlay').style.display='none';
          EventBus.$emit("open-coveo-search",this.searchOpen);
          if (this.isSearchPage) {
            setTimeout(()=>{
              EventBus.$emit("close-search");
            });
          }
        }
      },

        openSearch() {
          if (this.isSearchPage && !this.searchOpen) {
            this.searchOpen = !this.searchOpen;
            if (this.searchOpen) {
              document.body.classList.add('--search-open');
              document.body.style.overflow = "auto";
              document.querySelector('.nav-overlay').style.display='none';
              setTimeout(()=>{
                EventBus.$emit("open-search");
              });
            }
          }
          EventBus.$emit("open-coveo-search",this.searchOpen);
        },

      sendLaunchData(e){
        adobeLaunch.trackNavigationClick({
          "navigationMenuItem": e.target.textContent,
          "navigationLinkClicked": e.target.textContent
        });
      },

      hideNavPanel(e) {
        const navPanel = this.$refs.navpanel;

        if (navPanel
                && navPanel.classList.contains('--active')
                && !e.target.classList.contains('nav-item__link')
                && !e.target.classList.contains('nav-item__link-text')
                && !navPanel.contains(e.target))
        {
          this.closePanel(e);
        }
      }
    },
    created(){
          this.silosContent = this.globalNAVData.siloscontent.silos[0]
          this.sectionsContent = this.globalNAVData.silossectionscontent
     },
    mounted() {
      window.addEventListener('click', this.hideNavPanel);

        this.openSearch();

      let page = document.querySelector('.page');
      if (page) {
        page.classList.remove('small-nav-push');
      }
      // It's easiest to deal with our nav items as an array, so let's make one
      let items = this.$el.querySelectorAll('.nav-item');
      this.navItems = [].slice.call(items);
      if(this.isSearchPage || this.searchOpen){
        document.querySelector('header').style.position='unset';
      }
    },

    beforeDestroy() {
      // remove body class open modifiers
      document.body.classList.remove('--nav-open');
      this.closeSearch();
    }
  };
</script>

<style lang="scss">
  // Scroll blocker when the nav is open
  // Applied to the <body> element
  .--nav-open,
  .--search-open {
    overflow-y: hidden;
  }


  .global-header {

    .nav-wrapper {
      border: 2px solid $color--lighter-gray;
      border-width: 2px 0;
      position: relative;


      .nav {
        ul,
        li {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        a {
          color: $color--almost-black;
        }

        .box {
          align-items: stretch;
          flex-wrap: nowrap;
          height: 100%;
        }

        .nav-items {
          align-items: center;
          flex: 1 0 auto;
          padding-right: 20px;

          @include from-bp(1200px) {
            padding-left: 40px;
          }
        }

        .nav-item {
          padding-left: 20px;

          @include from-lg() {
            padding-left: 30px;
          }
        }

        .nav-item__link,
        .search__link {
          display: block;
          font-size: 15px;
          height: 100%;
          padding: 28px 0;
          position: relative;
          white-space: nowrap;

          .icon {
            color: $color--red;
            display: inline-block;
            font-size: 12px;
            height: 8px;
            line-height: 4px;
            margin-left: 4px;
            pointer-events: none;
            transform-origin: center;
            transition: all 0.25s ease;
            vertical-align: middle;
          }
        }

        .nav-logo {
          border-right: 2px solid $color--lighter-gray;

          &.--no-right-border {
            border-right: 0;
          }

          img {
            padding-right: 20px;
          }
        }

        .nav-search {
          border-left: 2px solid $color--lighter-gray;
          max-width: 112px;
          padding-left: 20px;
          padding-top: 2px;
          text-align: center;

          .nav-item__link,
          .search__link {
            position: relative;
            height: 100%;
            padding-bottom: 0;
            padding-top: 0;
            width: 100%;
            cursor: pointer;
          }

          .icon {
            position: absolute;
            top: 28px;
            font-size: 18px;
            height: calc(50% + 10px);
            line-height: 24px;
            width: 24px;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
             margin-left: 68px;
            }
          }

          .icon-search {
            color: $color--almost-black;
          }

          .icon-close {
            border-bottom: 4px solid $color--red;
          }
        }
      }

      .nav-icon-link {
        max-width: 130px;
        padding-right: 20px;

        img {
          display: block;
          max-width: 100%;
        }
      }

      .nav-item {
        &.--active {
          .icon {
            transform: rotateX(180deg);
          }

          .nav-item__link,
          .search__link {
            &::after {
              border-top: 4px solid $color--red;
              bottom: 0;
              content: '';
              display: block;
              left: 0;
              position: absolute;
              right: 0;
            }
          }

          // The red underline shouldn't go below the chevrons
          [aria-haspopup] {
            &::after {
              right: 20px;
            }
          }
        }
      }

      // Panel area where the dropdown navs render
      .nav-panel-container {
        position: relative;
      }

      .nav-panel {
        background: $color--almost-white;
        border: 2px solid $color--lighter-gray;
        border-width: 2px 0;
        display: none;
        padding: 40px 0;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 6;

        &.--active {
          display: block;
        }

        &:focus {
          outline: none;
        }

        // Contents of the panel
        ul,
        li {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        ul {
          margin-bottom: 40px;
        }

        li {
          margin-bottom: 12px;
        }

        a {
          color: $color--almost-black;
          display: table;
          font-size: 15px;

          @include hover {
            text-decoration: underline;
          }
        }

        .nav-submenu {
          flex-wrap: nowrap;
        }

        // Used on column 3 to make column 4 more visually separate
        .--border-right {
          border-right: 2px solid $color--lighter-gray;
          padding-right: 40px;
        }

        .cta {
          color: $color--red;
          font-size: 15px;
          margin-top: 24px;

          .icon {
            vertical-align: middle;
          }

          @include hover {
            color: $color--dark-red;
          }
        }

        &.--active ~ .nav-overlay {
          display: block;
        }
      }

      // The fourth column in the nav panel sometimes has an 'article block'
      .nav-article {
        p {
          font-size: 15px;
          margin-bottom: 0;
        }

        img {
          display: block;
          margin-bottom: 24px;
          max-width: 100%;

          // Unique breakpoint related to where the panel X sits
          @include to-bp(1360px) {
            max-width: calc(100% - 40px);
          }
        }

        // We only need to underline the title on focus/hover, not the date
        @include hover {
          text-decoration: none !important;

          p {
            text-decoration: underline;
          }
        }

        // Putting a focus outline on the image too makes it a little easier to see
        &:focus {
          img {
            outline: 1px solid;
          }
        }
      }

      .nav-overlay {
        background: rgba($color--almost-black, 0.7);
        display: none;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 5;

        .nav-panel.--active + & {
          display: block;
        }
      }

      .search-panel ~ .nav-overlay {
        display: block;
      }
      .coveo-search-panel ~ .nav-overlay {
        display: none;
      }
    }
  }

  // NGA and Monolith overrides
  .global-header {

    .nav-wrapper {

      a {
        border-bottom: 0;
        text-decoration: none;

        &:hover {
          color: inherit;
          border-bottom: 0;
          text-decoration: underline;
        }
      }
    }

    .nav {

      .box {

        @include from-lg {
          height: 83px;
        }
      }
    }
  }
</style>
