<template>
  <div>
    <div class="container emergency-alert__container" :class="content && (content.isHoldingsSearch==='true' || content.isLiterature==='true')? 'emergency-alert__container_for_Holdings_search' : ''">
      <div class="emergency-alert__content" :class="content && content.isHoldingsSearch==='true' ? 'holdings_search_info_flex' :''">
        <div class="emergency-alert__image" :class="content && content.isHoldingsSearch==='true' ? 'holdings_search_info_img' :''">
          <img v-show="content.hasEmergencyIEAlert==='false' && content.isHoldingsSearch!=='true'" :src="content.imageURL" alt />
          <Icon v-if="content && content.isHoldingsSearch==='true'" type="info_outline" />
        </div>

        <div class="emergency-alert__copy">
          <h2 class="emergency-alert__headline">{{content.heading}}</h2>
          <div v-if="isSmall">
            <p class="emergency-alert__detail" v-html="content.description"></p>
            <div v-if="content && (content.isHoldingsSearch!=='true' && content.isLiterature!=='true')">
            <CTA
              :url="content.link.URL"
              :inline="true"
              class="resources-url"
              :text="content.link.text"
              :target-blank="content.hasEmergencyIEAlert==='false'? false:true"
              :chevron="'right'"
            />
            </div>
          </div>
          <div v-else>
            <p class="emergency-alert__detail" v-html="content.description"></p>
              <div v-if="content && (content.isHoldingsSearch!=='true' && content.isLiterature!=='true')">
                <CTA
                  :url="content.link.URL"
                  :inline="true"
                  class="resources-url"
                  :text="content.link.text"
                  :target-blank="content.hasEmergencyIEAlert==='false'? false:true"
                  :chevron="'right'"
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.emergency-alert {
  background-color: $color--bg-gray;
  box-shadow: 0 2px 4px 0 rgba($color--gray, 0.5);
  position: absolute;
  width: 100%;
  z-index: 5;

  &__container {
    padding: 16px 10px;

    @include from-md {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 20px 10px;
    }
  }

  &__content {
    @include from-md {
      align-items: center;
      display: flex;
    }
  }

  &__image {
    @include aspect-ratio(1, 1);

    margin-right: 20px;
    max-width: 44px;

    @include to-md {
      display: none;
    }

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  &__headline,
  &__detail {
    font-size: 14px;
    @include to-md() {
      margin-right: 23%;
    }
  }

  &__headline {
    margin-bottom: 5px;
  }

  .cta {
    margin-top: 10px;
    white-space: nowrap;

    @include from-md {
      margin-top: 0;
    }
  }
}

// Themed style variants
.emergency-alert--warning {
  background: $color--yellow;
  color: $color--almost-black;
}

.emergency-alert--internal,
.emergency-alert--urgent {
  color: $color--white;
}

.emergency-alert--internal {
  background: $color--dark-teal;
}

.emergency-alert--urgent {
  background: $color--red;
}

// Only the default style uses a red cta
.emergency-alert--warning,
.emergency-alert--internal,
.emergency-alert--urgent {
  .cta {
    color: inherit !important;
  }
}

.resources-url {
  font-size: 14px;
  @include from-md() {
    margin-left: 20px;
  }
}
.holdings_search_info_img{
 display: block;
}
.holdings_search_info_flex{
  align-items: center;
  display: flex;
}
  .icon-info_outline{
    font-size: 30px;
    font-style: unset;
  }
.holdings_search_info_flex > div.emergency-alert__copy > div > p{
  margin-bottom: 10px;
}
</style>

<script>
import CTA from "@vg-faset/ui/src/components/cta/CTA";
import Icon from "@vg-faset/ui/src/components/icon/Icon";

export default {
  name: "AlertContent",
  components: { CTA, Icon },
  props: {
    content: {
      type: Object
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  }
};
</script>
