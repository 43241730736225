import axios from "axios";
import EXT_DEV_CONFIG_JSON from "../../../config/environment-config-external.json";
const EXT_DEV_CONFIG = EXT_DEV_CONFIG_JSON.development;
const ENDPOINTS = require("../../../config/endpoints.constant");

let self = {};

self.requested = false;
self.isDevMode = process.env.isDevMode;
self.envConfig = {};

self.eStudioHost = host => host.includes(EXT_DEV_CONFIG.eStudioHost);

self.get = async () => {
  if (!self.requested) {
    const endpoint = self.buildWebappRegionURL() + ENDPOINTS.ENVIRONMENT_CONFIG;

    self.requested = true;
    self.envConfig = axios.get(endpoint).then(response => response.data);
  }

  return self.envConfig;
};

self.isPreProd = path => path.includes("-pre");

self.buildWebappRegionURL = () => {
  const regionURL = ENDPOINTS.GLOBAL_NAV_WEBAPP;

	return self.isDevMode
		? ""
		: self.eStudioHost(window.location.host)
        ? `${EXT_DEV_CONFIG.advisorDomain}${regionURL}`
		: regionURL;
};

self.checkSiteInternalOrExternal = async () => {
    const curLoc = window.location.host;
    const isInternal = curLoc.indexOf("fasi.vanguard.com")>-1;
    const isInternalTest = curLoc.indexOf("testfasi.vanguard.com")>-1
    const isExternalTest = curLoc.indexOf("testadvisors.vanguard.com")>-1;
    let pageConfig = await self.get();
    let url = '';
    if(isInternal){
        if(isInternalTest){
            url = `${pageConfig.testAdvisorDomain}`;
        }else{
            url = `${pageConfig.advisorDomain}`;
        }
    }else{
        if(isExternalTest){
            url = `${pageConfig.testAdvisorDomain}`;
        }else{
            url = `${pageConfig.advisorDomain}`;
        }
    }
    return url;
};


export default self;
