const ENDPOINTS = {
    ENVIRONMENT_CONFIG: "/env-config",
    GLOBAL_NAV_WEBAPP: "/fasglobalnavigation",
    GLOBAL_NAV_WEBAPP_PRE_PROD: "/fasglobalnavigation-pre",
    GET_LOGGED_ON_STATUS: "/secure/getLoggedOnStatus",
    GLOBAL_HEADER_CONTENT_PATH: '/pageid/globalheader/project/xfa',
    GLOBAL_FOOTER_CONTENT_PATH: '/pageid/globalfooter/project/xfa',
    SEARCH_SERVICE_PATH: "/public/xsw/public/search/autosuggest/global",
    SEARCH_QUERY_PARAMS_PUBLIC: "?query=",
    GLOBAL_HEADER_CONTENT: "/global-header-content",
    GLOBAL_FOOTER_CONTENT: "/global-footer-content",
    GET_SMSESSION: "/getSMSession",
    LOGGER: '/flk-logging',
    VANGUARD_FUNDS: '/vanguard-funds',
    SEARCH_RESULTS_URL: '/search-results',
    PRODUCTS_URL: '/investments/products/',
    COVEO_SECURE_TOKEN_URL: '/web/ecs/fus-search-results-coveo-webapp/coveo/token',
    COVEO_SEARCH_RESULTS_URL: '/searchresults',
    COVEO_SEARCH_URL: '/rest/search/',
    SAVE_REQUEST :'/save-request',
    FIRM_LIST :'/auth/xrg/public/registration/firm/firmlist',
    SALES_FORCE_NEWS_LETTER : "/web/ecs/ft5-subscription-center/preferences/newsletter",
    AEM_GLOBAL_HEADER_CONTENT_PATH: '/globalheader/public.caas.json',
    AEM_GLOBAL_FOOTER_CONTENT_PATH: '/globalfooter/public.caas.json',
    CLEAR_VG_SESSION_URL: '/clearVGSession'
};

module.exports = ENDPOINTS;
