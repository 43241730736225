const SYMBOLS = {
    "!": "%21",
    '"': "%22",
    "#": "%23",
    "$": "%24",
    "%": "%25",
    "&": "%26",
    "'": "%27",
    "(": "%28",
    ")": "%29",
    "*": "%2a",
    "+": "%2B",
    ",": "%E2%80%9A",
    "-": "-",
    ".": "%E2%8B%85",
    "/": "%2F",
    ":": "%3A",
    ";": "%3B",
    "<": "%26lt%3B",
    "=": "%3D",
    ">": "%26gt%3B",
    "?": "%3F",
    "@": "%40",
    "[": "%5B",
    "\\": "%5C",
    "]": "%5D",
    "^": "%5E",
    "_": "_",
    "`": "%60",
    "{": "%7B",
    "|": "%7C",
    "}": "%7D",
    "~": "%7E"
};

module.exports = SYMBOLS;
