import { debounce } from 'lodash';
import { EventBus } from '@vg-faset/core';

export default {
  data() {
    return {
      pageUrl: undefined,
      topPosition: null,
      fullHeader: true
    };
  },
  methods: {
   onPageResize: debounce (function () {
      this.positionHeader();
    }, 100),
    positionHeader () {

      let headerHeight;
      if (this.$refs.globalHeader && !window.document.body.classList.contains('fas-header-relative')) {
        headerHeight = this.$refs.globalHeader.clientHeight;
        window.document.body.style.marginTop = `${headerHeight}px`;
        /*
         remove this line and uncomment code below to reinstate specific page hacks
         this.topPosition = headerHeight;
         */
      }

      if (headerHeight) {
        const ngaPages = [
          'advisor/investments/ltqt',
          'advisor/globalelements/contactus',
          'advisorpro/myprofile/profilesettings',
          'advisor/globalelements/specialnotice',
          'investments/benchmark/benchmarkcomparison'
        ];

        const offsetPages = [
          'advisor/globalelements/contactus',
          'investments/benchmark/benchmarkcomparison'
        ];

        const breakpoint = this.model.breakpoint;
        let isNgaPage = false;
        let includeOffset = false;

        ngaPages.forEach((page) => {
          if (this.pageUrl.indexOf(page) > -1) {

            // is URL matching the list?
            isNgaPage = true;

            // set as true if page is an offset page and breakpoint is not small.
            includeOffset = offsetPages.some((offsetPage) => {
              return page.indexOf(offsetPage) > -1 && breakpoint !== 'small';
            });
          }
        });

        // add 18px to headerHeight if this is determined to be an offset page.

        this.topPosition = isNgaPage
          && includeOffset
            ? headerHeight + 18
            : headerHeight;
      }
    },
    checkMonolithPages () {
      if (document.body.classList) {
        const bodyClassList = document.body.classList;

        // check for template12Grid, add it if it's gone
        const monolithPages = [
          'analysistools/findsimilarproducts',
          'globalelements/contactus',
          'analysistools/holdingssearch',
          'analysistools/prodscreener',
          'analysistools'
        ];
        monolithPages.forEach((page) => {
          if (this.pageUrl.indexOf(page) > -1) {
            setTimeout(() => {
              bodyClassList.add('template12Grid');
            }, 1000);
          }
        });

        // check for fas-header-relative, add it if it's gone
        const monolithPagesRelativeHeader = [
          'analysistools/holdingssearch',
          'analysistools/prodscreener',
          'analysistools'
        ];
        monolithPagesRelativeHeader.forEach((page) => {
          if (this.pageUrl.indexOf(page) > -1 && !bodyClassList.contains('fas-header-relative')) {
            bodyClassList.add('fas-header-relative');
          }
        });
      }
    },
    checkForNavlessHeader () {
      const navless = window.document.getElementById('navless');
      if (navless && navless.getAttribute('data-navless-header') === 'true') {
        this.fullHeader = false;
      }
    }
  },
  created() {
    window.addEventListener('resize', this.onPageResize);
    this.pageUrl = window.document.location.href;
  },
  mounted() {
    this.checkForNavlessHeader();
    this.positionHeader();
    EventBus.$on("position-header", this.positionHeader);
    EventBus.$on("close-search", this.positionHeader);
    EventBus.$on("open-search", this.positionHeader);
    window.onload = () => {
      this.checkMonolithPages()
    };
  },
  updated() {
    if (this.navData) {
      this.positionHeader();
    }
    EventBus.$emit('modal:set-id', 'nav-modal-id');
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onPageResize);
  }
};
