import axios from "axios";
import EnvConfigService from '@/js/services/envConfig.service';
import logger from '@/js/services/logger.service';
let ENDPOINTS = require('../../../config/endpoints.constant');

let self = {};
self.getCoveoSecureToken = async () => {
    const domainSiteUrl = await EnvConfigService.checkSiteInternalOrExternal();
    let coveoTokenUrl = domainSiteUrl + ENDPOINTS.COVEO_SECURE_TOKEN_URL;
    return new Promise((resolve, reject) => {
        axios.get(coveoTokenUrl)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
                logger.log("Failed to get Coveo secure token.");

            });
    });
}

export default self;
