import axios from 'axios';
import EnvConfigService from '@/js/services/envConfig.service';

let ENDPOINTS = require('../../../config/endpoints.constant');

let self = {};

self.advancedCertification = async (payload, consumerID) => {
    let url = EnvConfigService.buildWebappRegionURL() + ENDPOINTS.SAVE_REQUEST + "?consumerID=" + consumerID;
    let matchData;
    await axios.post(url,payload).then(response=>{
        matchData = response.data;
    }).catch ((error) =>{
        console.log(
            `Error occurred while trying to post cert layer data with error: ${error}`
        );
        matchData = undefined;
    });
    return matchData
};

export default self;
