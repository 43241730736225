var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.navData)?_c('header',{ref:"globalHeader",class:['fas-core', 'global-header', { 'global-header--sticky': _vm.sticky }],style:(_vm.topPosition ? `margin-top: -${_vm.topPosition}px` : '')},[_c('div',[_c('a',{staticClass:"skip-to-main",attrs:{"href":"javascript:;","tabindex":"0","role":"link"},on:{"click":(event) => _vm.handleSkipClick(event)}},[_vm._v("Skip to main content")])]),_vm._v(" "),_c('div',{staticClass:"ie-banner"},[_c('v-breakpoint',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_vm._v(" "),(_vm.showIEAlert)?_c('EmergencyAlert',{attrs:{"content":_vm.navData.commoncontent.commonContent.emergencyiealert}}):_vm._e()],1),_vm._v(" "),_c('GlobalToolbar',{attrs:{"globalNAVData":_vm.navData,"isLoggedOn":_vm.isLoggedOn,"fullHeader":_vm.fullHeader}}),_vm._v(" "),(_vm.model.isLarge)?_c('GlobalNavLarge',{attrs:{"globalNAVData":_vm.navData,"isLoggedOn":_vm.isLoggedOn,"fullHeader":_vm.fullHeader,"isSearchPage":_vm.searchPage,"afterCoveoInit":_vm.afterCoveoInit,"toggleCoveoSearch":_vm.toggleCoveoSearch,"scriptsLoaded":_vm.scriptsLoaded}}):(!_vm.model.isLarge)?_c('GlobalNavSmall',{attrs:{"globalNAVData":_vm.navData,"isLoggedOn":_vm.isLoggedOn,"fullHeader":_vm.fullHeader,"isSearchPage":_vm.searchPage,"afterCoveoInit":_vm.afterCoveoInit,"toggleCoveoSearch":_vm.toggleCoveoSearch,"scriptsLoaded":_vm.scriptsLoaded}}):_vm._e(),_vm._v(" "),_c('CoveoSearchbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggleCoveoSearch && _vm.isSearchOpen),expression:"toggleCoveoSearch && isSearchOpen"}],class:[
      _vm.model.isLarge ? 'search-panel-large' : 'search-panel-small',
      _vm.showCoveoSearchBar ? 'show-coveo-search' : 'hide-coveo-search'
    ],attrs:{"content":_vm.navData,"searchPage":_vm.searchPage,"coveoConfigs":_vm.coveoConfigs}}),_vm._v(" "),(_vm.fullHeader)?_c('Modal'):_vm._e(),_vm._v(" "),(_vm.showAlert)?_c('EmergencyAlert',{attrs:{"content":_vm.navData.commoncontent.commonContent.emergencyalert}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"holdingsSearch-banner"},[(_vm.isHoldingsSearch || _vm.isLiterature)?_c('EmergencyAlert',{attrs:{"content":_vm.isHoldingsSearch
          ? _vm.navData.commoncontent.commonContent
              .emergencyalertforholdingssearch
          : _vm.navData.commoncontent.commonContent.emergencyalertforliterature}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"crc-banner"},[(_vm.isCRCPage)?_c('EmergencyAlert',{attrs:{"content":_vm.navData.commoncontent.commonContent.emergencyalertforcrcpages}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }