// Tab listeners for handling focus in the global nav
// and for setting an appropriate aria-label on the open submenu
export default {
  methods: {
    // If tabbing backwards out of the panel, return focus to the top-level link
    onTabBack(e) {
      if (e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        this.$emit('tab:out');
      }
    },
    // If tabbing forwards, move focus to the next top-level link
    onTabForward(e) {
      if (!e.shiftKey && e.key === 'Tab') {
        this.$emit('tab:out');
      }
    },
    closePanel() {
      if (this.$parent.closePanel) {
        this.$parent.closePanel();
      }
    }
  },
  mounted() {
    this.$parent.currentSubnavLabel = this.label;
  }
};