<template>
  <div id="search-panel"
       class="search-panel"
       role="navigation"
       @keydown="searchPanelKeydown"
       >
    <!-- Vanguard to add form action and method based on their backend -->
    <form id="global-nav-search"
          autocomplete="off"
          role="search"
          class="container search-panel__form">
      <input
        class="search-panel__input"
        ref="searchInput"
        type="search"
        :placeholder="searchBarData.longText"
        v-model="currentVal"
        @input="searchTyping"
        aria-label="Search Input"
      />
      <button type="submit"
        :aria-disabled="inputIsEmpty"
        @blur="submitBlur"
        @click.prevent="submitResult"
        class="button--unstyled icon icon-return search-panel__submit"
        :class="{'--grayed': inputIsEmpty}">
        <span class="visually-hidden">Submit</span>
      </button>
    </form>
    <div v-if="respSearchData" class="search-results" >
      <div class="container">
        <ul ref="resultsList"
            v-if="respSearchData.results"
            class="search-results__list">
          <template v-if="respSearchData.results.length">
            <li v-for="(item, i) in filteredSearchResults.suggestions" :key="'search-return-'+i">
              <a  v-if="item.isFund" @click="trackAutoSuggest($event,item.pdpLink)"  :href="item.pdpLink" class="search-results__item">
                {{item.tickerSymbol}} {{item.term}} ({{item.fundID}})
              </a>
              <a v-else @click="trackAutoSuggest($event,item.termLink)" :href="item.termLink" class="search-results__item">
                {{item.term}}
              </a>
              <a v-if="item.isFund && item.litID" @click="trackAutoSuggest($event,item.factSheetLink)" :href="item.factSheetLink" class="search-results__factsheet" v-html="searchBarData.factSheetLabel"></a>
            </li>
          </template>
          <li class="search-results__all">
            <a :href="filteredSearchResults.all.link" v-html="filteredSearchResults.all.text" @click="trackAllResults($event,filteredSearchResults.all.link)">
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import searchService from '../../services/searchService.js';
import GlobalSearchDataFilter from '../../js/GlobalSearchDataFilter.js';
import { adobeLaunch } from 'vg-fas-adobe-launch';
import logger from "@/js/services/logger.service";
import frapieService from '../../services/frapieService.js';
import SYMBOLS from "../../../config/symbols.constant";
import ENDPOINTS from "../../../config/endpoints.constant";

export default {
  name: 'SearchBox',
  props: {
    closePanel: {
      type: Function,
      required: true
    },
    content: {
      type: Object
    },
    isLoggedOn: {
      type: Boolean
    },
    isInternalSite: {
      type: Boolean
    },
    isSearchPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      respSearchData: null,
      currentVal: '',
      focusableResults: [],
      focusIndex: 0,
      shiftPressed: false,
      inputIsEmpty: true,
      searchFilterObj: new GlobalSearchDataFilter(),
        searchBarData: this.content.searchutilitybar,
        queryTerm: this.content.searchutilitybar.queryTerm,
        submitURL: this.isLoggedOn ? this.content.searchutilitybar.secureSubmitSearchUrl : this.content.searchutilitybar.unSecureSubmitSearchUrl,
      internalSearchOption: 'all',
      isExactMatch: false,
      frapieFundsData: {},
      exactMatchTickerData: {},
      searchTypeGlobal : 'global',
      searchTypeAutoSuggest: 'auto suggest'
    };
  },
  watch: {
    respSearchData(newVal) {
      this.$nextTick(function() {
        if (newVal) {
          if(this.$refs.resultsList) {
            this.focusableResults = this.$refs.resultsList.querySelectorAll('li a:first-child');
          }
        } else {
          this.focusIndex = 0;
        }
      });
    }
  },

  methods: {
    currentQuery(){
      if (this.isSearchPage) {
        const queryURL = new URLSearchParams(window.location.search);
        let userQuery  = queryURL.get("query");
        this.currentVal = userQuery ? userQuery.split('&lt;').join('<').split('&gt;').join('>') : "";
      }
    },
    trackAllResults(e, url){
      e.preventDefault();
      this.trackSearchConducted(this.searchTypeGlobal);
      setTimeout( function() {
        window.location.assign(url);
      }, 500);
    },

    trackSearchConducted(searchType) {
      let searchInfo = {};
      searchInfo.searchTermEntered = this.$refs.searchInput.value;
      searchInfo.searchType = searchType;
      adobeLaunch.trackSearchConducted(searchInfo);
    },

    formatQueryString(query) {
      let queryArray;
      queryArray = query.split("");
      for (let i = 0; i < queryArray.length; i++) {
        let queryChar = queryArray[i];
        for (let j = 0; j < Object.keys(SYMBOLS).length; j++) {
          if (queryChar === Object.keys(SYMBOLS)[j]) {
            queryChar = Object.values(SYMBOLS)[j];
          }
          else {
            queryArray[i] = queryChar;
          }
        }
      }
      return queryArray.join('');
    },

    async exactTickerMatch(searchedQuery) {
      this.frapieFundsData = await frapieService.getFrapieData();
      if (this.frapieFundsData) {
        this.frapieFundsData.fund.forEach((investment) => {
          if (
                  investment.profile.ticker === searchedQuery.toUpperCase() ||
                  investment.profile.portId === searchedQuery.toUpperCase() ||
                  investment.profile.cusip === searchedQuery
          ) {
            this.isExactMatch = true;
            this.exactMatchTickerData = investment;
          }
        });
      }
    },

    async submitResult() {
      if (this.currentVal) {
        await this.exactTickerMatch(this.currentVal);
        let formattedQuery = this.formatQueryString(this.currentVal);
        let redirectPath = function (url) {
          setTimeout(function () {
            window.location.assign(url);
          },500)
        }
        if (this.isExactMatch) {
          this.trackSearchConducted(this.searchTypeAutoSuggest);
          let fundIdentifier = this.exactMatchTickerData.profile.ticker ? this.exactMatchTickerData.profile.ticker : this.exactMatchTickerData.profile.portId;
          return redirectPath( `${ENDPOINTS.PRODUCTS_URL}${fundIdentifier}`);
        } else {
          this.trackSearchConducted(this.searchTypeGlobal);
          return redirectPath(this.searchFilterObj.getFullText(this.submitURL.replace(/&amp;/g, '&')) + "&" + this.queryTerm + "=" + formattedQuery);
        }
      }
    },

    searchPanelKeydown(event) {
      this.shiftPressed = event.shiftKey;

      if (event.key === "Escape") {
        if(this.isSearchPage){
          this.respSearchData = null;
          return;
        } else {
          this.closePanel();
          return;
        }
      }
      if (event.key === "Enter" && this.currentVal && event.target.closest('.search-results__list') === null) {
          event.preventDefault();
          this.submitResult();
          return;
      }
      if (event.target.classList.contains('search-panel__input')) {
        this.moveFocus(event);
        return;
      }

      if (
        event.target.classList.contains('search-results__list') ||
        event.target.parentElement.classList.contains('search-results__list') ||
        event.target.parentElement.parentElement.classList.contains('search-results__list')
      ) {
        this.traverseResults(event);
        return;
      }
    },

    searchTyping(e) {
      let val = e.target.value;
      this.inputIsEmpty = val.length === 0;
      this.currentVal = val;
      if (val.length >= 2) {
        this.fetchResults(val);
      } else {
        this.respSearchData = null;
      }
    },

    moveFocus(e) {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        this.focusResults('down');
        return;
      }
      if (e.key === "ArrowUp") {
        e.preventDefault();
        this.focusResults('up');
        return;
      }
    },

    submitBlur() {
      // if results is not showing when blurring off the submit button then
      // it should close the search panel
      if (!this.$refs.resultsList && !this.shiftPressed) {
        if (this.isSearchPage) {
          return;
        } else {
          this.closePanel();
        }
      }
    },

    closeSearchPanel(e) {
      if (e.target.closest('.search-panel') === null && e.target.closest('.nav-search') === null
              && e.target.closest('.small-nav-search') === null) {
        if (this.isSearchPage) {
          this.respSearchData = null;
        } else {
          this.closePanel(e);
        }
      }
    },

    // when using up/down while still focused inside the input
    focusResults(dir) {
      if (!this.$refs.resultsList) {
        return;
      }

      if (dir === 'down') {
        this.focusableResults[0].focus();
        return;
      }

      if (dir === 'up') {
        this.focusIndex = this.focusableResults.length - 1;
        this.focusableResults[this.focusIndex].focus();
        return;
      }
    },

    traverseUp() {
      this.focusIndex--;
      if (this.focusIndex < 0) {
        this.focusIndex = this.focusableResults.length - 1;
      }
      this.focusableResults[this.focusIndex].focus();
    },

    traversDown() {
      this.focusIndex++;
      if (this.focusIndex === this.focusableResults.length) {
        this.focusIndex = 0;
      }
      this.focusableResults[this.focusIndex].focus();
    },

    traverseResults(e) {
      switch (e.key) {
        case "ArrowDown":
        case "ArrowRight":
          e.preventDefault();
          this.traversDown();
          break;
        case "ArrowUp":
        case "ArrowLeft":
          e.preventDefault();
          this.traverseUp();
          break;
        case "Tab":
          if (
            this.focusableResults.length > 0 &&
            e.target === this.focusableResults[this.focusableResults.length - 1]
          ) {
            // if we're tabbing and on the last element, we should exit
            if (this.isSearchPage) {
              this.respSearchData = null;
            } else {
              this.closePanel(e);
            }
          }
          break;
        default:
          return;
      }
    },

    async fetchResults(searchQuery) {
      let vm = this;
      let response;
      try {
        response = await searchService.getSearchData(searchQuery.toUpperCase());
        if(response) {
          vm.respSearchData = response.data;
          vm.filteredSearchResults = vm.searchFilterObj.getFilteredResults(vm.respSearchData, vm.searchBarData, searchQuery, vm.isLoggedOn);
          logger.log(`globalnavigation-webapp searchPanel: searchQuery=${searchQuery}`);
        }
      } catch(error) {
        logger.log('globalnavigation-webapp: Error in SearchPanel.vue while displaying search results ' + JSON.stringify(error));
        console.error(error);
      }
    },
    trackAutoSuggest(e, url){
      e.preventDefault();
      if(e.target.nodeName === "A" && (e.target.className==="search-results__item" || e.target.className==="search-results__factsheet")) {
        let searchInfoObj = {};
        searchInfoObj.searchTermEntered = document.querySelector('input.search-panel__input').value;
        if(e.target.textContent === "Fact sheet"){
          searchInfoObj.resultClicked = e.target.previousElementSibling.textContent.trim();
          searchInfoObj.autoSuggestMediaType = "Fact Sheet";
        }else {
          searchInfoObj.resultClicked = e.target.textContent.trim();
        }
        let autoSuggestResults = e.target.parentNode.parentNode.childNodes;
        searchInfoObj.autoSuggestTermSelectedPosition = Array.prototype.indexOf.call(autoSuggestResults, e.target.parentNode)+1;
        searchInfoObj.searchType = e.target.href.indexOf('/search-results')> -1 ? this.searchTypeGlobal : this.searchTypeAutoSuggest;
        adobeLaunch.trackSearchConducted(searchInfoObj);
      }
      setTimeout( function() {
        if(e.target.textContent === "Fact sheet") {
          window.open(url);
        } else {
          window.location.assign(url);
        }
      }, 500);
    }
  },

  mounted() {
    if (!this.isSearchPage) {
      this.$refs.searchInput.focus();
    }
    window.addEventListener('click', this.closeSearchPanel);
    this.currentQuery();
  }
};
</script>

<style lang="scss">
/******************************************
  Search Small overrides
*/
.--small-search-open {

  .global-header {

    .search-panel-small {
     // padding: 20px 0 0;
      top: 90px;

      &--search {
        top:100%;
        position: relative;
      }

      .container {
        height: 100%;
      }

      .search-panel__form {
        padding-bottom: 20px;
      }

      .search-panel__input {
        font-size: 18px;
        margin-right: 5px;
        padding: 2px 2px 0;
      }

      .search-panel__submit {
        flex: 0 1 5%;
        @include to-md() {
          flex: 0 1 10%;
        }
        transform: rotate(180deg);
      }

      .search-results {
        // 171px is height of the header with search input area showing
        max-height: calc(100vh - 171px);
      }

      .search-results__list {
        max-width: 100%;

        li {
          padding: 27px 0;
        }
      }

      .search-results__item {
        margin-right: 25px;
      }

      .search-results__factsheet {
        white-space: nowrap;
        display: inline-flex;
      }
    }
  }
}

.global-header {

  .search-panel {
    background: $color--almost-white;
    border: 2px solid $color--lighter-gray;
    border-width: 2px 0 1px 0;
    display: block;
    padding: 28px 0 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 7;

    &--search {
        position: relative;
    }

    // Contents of the panel
    ul,
    li {
      list-style: none;
      margin: 0;
    }

    a {
      color: $color--almost-black;
      display: table;
      font-size: 15px;

      @include hover {
        text-decoration: underline;
      }
    }

    .search-panel {

      &__form {
        display: flex;
        padding-bottom: 28px;
      }

      &__input {
        appearance: none; // Safari required this so I could style the input
        -webkit-appearance: none; // Additional Safari override
        background: $color--almost-white;
        border: 0;
        display: block;
        flex: 1;
        font-family: inherit;
        font-size: 24px;
        line-height: 1;
        padding: 6px 5px 0 5px;
        @media screen and (-webkit-min-device-pixel-ratio:0){
          color:#232323;
        }

        &::placeholder {
          color: $color--gray;
        }
      }

      &__submit {
        flex: 0 1 5%;
        @include to-lg() {
          flex: 0 1 6%;
        }
        font-size: 20px;
        max-width: 112px;
        padding-bottom: 10px;
        text-align: center;
        cursor: pointer;
        transform: rotate(180deg);

        &:focus {
          color: $color--black;
        }

        // make the submit button gray when the input
        // does not have text and is not in focus
        &.--grayed {
          color: $color--gray;
        }
      }

    }

    .search-results {
      background-color: $color--white;
      // on Desktop, 227px is the height of all the global header elements
      // including the search input area
      max-height: calc(100vh - 227px);
      overflow-y: auto;

      &__factsheet {
        color: $color--red;
        font-size: 14px;
        font-weight: $font-weight--bold;
      }

      &__list {
        margin-bottom: 0;
        max-width: 97.5%;

        li {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 0;
          padding: 20px 0;
        }
      }

      &__link {
        border-top: 2px solid $color--lighter-gray;
        font-weight: $font-weight--bold;
      }

      &__all {
        a {
          color: $color--red;
          font-weight: $font-weight--bold;
        }
      }
    }

    // eConnect search option buttons
    .search-options__button {
      min-width: 120px;
      margin: 0 0 24px 0;
      padding: 16px 0;
      font-size: 12px;
      color: #89bbb9;
      border: 2px solid #afd3d0;

      &.--selected {
        color: #298883;
        border: 2px solid #247d78;
      }
    }
  }
}


//NGA and Monolith overrides
.global-header {

  .search-panel {

    .search-panel__input {
      height: initial;
      border: initial;
      padding-left: 0;
      padding-right: 0;

      &:focus {
        border: initial !important; // needs !important to override app.min.css's !important declaration
      }
    }
  }
}
</style>
